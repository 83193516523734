<template>
  <div class="deaggable-wrapper">
    <draggable v-if="sortArr.length != 0" v-model="sortArr" group="people" @start="drag = true" @end="drag = false" item-key="id">
      <template #item="{ element }">
        <div class="draggable-box" v-if="!(element.columnKey === 'account_id' && fixedList.length > 0)">
          <div class="flexJustifyBetween flexAlignCenter">
            <MenuOutlined class="mr10" />
            <div style="word-break: break-all">{{ element.columnValue }}</div>
          </div>
          <CloseOutlined class="draggable-close cursorPoi" @click="deleteDraggable(element)" />
        </div>
      </template>
    </draggable>

    <a-empty v-else :image="simpleImage" style="margin: 0">
      <template #description>
        <span>暂无数据</span>
      </template>
    </a-empty>
  </div>
</template>
<script>
import { MenuOutlined, CloseOutlined } from '@ant-design/icons-vue';
import { Empty } from 'ant-design-vue';
import draggable from 'vuedraggable';
export default {
  props: {
    draggableList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    fixedList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    title: {
      type: String,
      default: () => {
        return '';
      },
    },
  },
  emits: ['getDraggableList', 'deleteDraggable'],
  components: {
    draggable,
    MenuOutlined,
    CloseOutlined,
  },
  data() {
    return {
      // 排序的数组
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      sortArr: [],
      drag: false,
    };
  },
  watch: {
    draggableList(newVal) {
      this.sortArr = newVal;
    },
    sortArr: {
      handler(newVal) {
        this.$emit('getDraggableList', newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.sortArr = this.draggableList;
  },
  methods: {
    deleteDraggable(value) {
      this.$emit('deleteDraggable', value);
    },
  },
};
</script>
<style lang="less" scoped>
.deaggable-wrapper {
  height: 1px;
  flex-grow: 1;
  background: #fff;
  & > div:first-child {
    background: #f7f7f7;
    height: 100%;
    overflow: auto;
  }
}
.draggable-box {
  width: 100%;
  min-height: 0.75rem;
  background: #fff;
  color: #696969;
  padding: 0.1875rem 0.375rem;
  margin-bottom: 0.25rem;
  display: flex;
  cursor: all-scroll;
  justify-content: space-between;
  align-items: center;
  .draggable-close {
    display: none;
    color: #8c8c8c;
  }
  &:hover {
    background: #dfe9f9;
  }
  &:hover .draggable-close {
    display: inline-block;
  }
}
</style>