<template>
  <a-modal class="customize-column" v-model:visible="customizeModalVisible" :title="null" :footer="null" width="31.31rem" centered :mask-closable="false" @cancel="onCancelClick">
    <a-card :bordered="false" style="width: 100%">
      <template #title>
        <span class="font16 weight">自定义{{ customizeTitle }}</span> <span class="title-tips" v-if="titleTips">{{ titleTips }}</span>
      </template>
      <template #extra>
        <a-input-search v-model:value="searchValue" placeholder="请输入指标名称搜索" style="width: 7.1875rem" allow-clear @search="onSearchValue" />
      </template>
      <a-row class="customize-top">
        <a-col :span="19" class="customize-left">
          <a-row class="customize-content">
            <a-col :span="5">
              <a-menu id="menuLists" class="menu-list" v-model:selectedKeys="selectedKeys" v-model:openKeys="openKeys" mode="inline" @openChange="openKeyChange">
                <template v-for="menu in menuArray" :key="menu.key">
                  <a-sub-menu v-if="menu.info?.[0]?.info" :key="menu.key">
                    <template #expandIcon>
                      <span style="display: none">1</span>
                    </template>
                    <template #title>
                      <a :href="`#${menu.key}`" class="color85">{{ menu.name }}</a>
                    </template>
                    <a-menu-item v-for="childMenu in menu.info" :key="childMenu.key">
                      <a :href="`#${childMenu.key}`">{{ childMenu.name }}</a>
                    </a-menu-item>
                  </a-sub-menu>
                  <a-menu-item v-else :key="menu.key">
                    <a :href="`#${menu.key}`">{{ menu.name }}</a>
                  </a-menu-item>
                </template>
              </a-menu>
            </a-col>
            <a-col :span="19" class="customize-list" v-if="loadCheckBox">
              <a-card v-for="menu in menuArray" :key="menu.key" :id="menu.key" :bordered="false" @click.stop="selectedKey(menu.info && menu.info[0].key, menu.key)">
                <template #title>
                  <a-checkbox
                    :disabled="menu.disabled || isSearch"
                    v-if="!menu.info?.[0]?.info"
                    :checked="getArrayIndex(checkAllList, menu.key) !== -1"
                    :indeterminate="getArrayIndex(indeterminateList, menu.key) !== -1"
                    @change="
                      e => {
                        onCheckAllChange(e, menu.key, null);
                      }
                    "
                  >
                  </a-checkbox>
                  <span class="font14 weight cursorPoi pl10">{{ menu.name }}</span>
                  <span v-if="!menu.info?.[0]?.info" style="color: #666; font-size: 0.375rem">{{ ` (已选${checkedList?.[menu.key]?.length || 0})` }}</span>
                </template>
                <div v-if="!menu.info?.[0]?.info">
                  <a-checkbox-group
                    class="checkbox-wrapper"
                    v-show="!getCollapse(menu.key)"
                    v-model:value="checkedList[menu.key]"
                    @change="
                      checkedValue => {
                        onCheckChange(checkedValue, menu.key, null);
                      }
                    "
                  >
                    <a-row>
                      <template v-for="info in menu.info" :key="info.columnKey">
                        <!-- 当有 secondModuleName 时,单独占一行 -->
                        <a-col :span="24" v-if="info.secondModuleName && info.childrenSort === 1">
                          <div class="font12 weight">
                            {{ info.secondModuleName }}
                          </div>
                        </a-col>
                        <!-- 当没有secondModuleName时，但是secondLevelSort为0时,单独占一行 -->
                        <a-col :span="8" style="display: inline-block;" v-if="!info.hide">
                          <a-checkbox v-model:value="info.columnKey" :disabled="isDisabled(info)" v-if="showItem(info)">
                            <a-tooltip>
                              <template #title> {{ getTitle(info) }} </template>
                              <span class="textHide check-label">{{ info.columnValue }}</span>
                            </a-tooltip>
                          </a-checkbox>
                        </a-col>
                      </template>
                    </a-row>
                  </a-checkbox-group>
                </div>
                <!-- v-else此处暂时用不上 未做调整 -->
                <div v-else>
                  <a-card v-for="childMenu in menu.info" :key="childMenu.key" :id="childMenu.key" :bordered="false" @click.stop="selectedKey(childMenu.key, menu.key)">
                    <template #title>
                      <div class="flexJustifyBetween">
                        <div>
                          <a-checkbox
                            :disabled="childMenu.disabled || isSearch"
                            :checked="getArrayIndex(checkAllList, childMenu.key) !== -1"
                            :indeterminate="getArrayIndex(indeterminateList, childMenu.key) !== -1"
                            @change="
                              e => {
                                onCheckAllChange(e, menu.key, childMenu.key);
                              }
                            "
                          >
                          </a-checkbox>
                          <span class="font14 weight cursorPoi pl10">{{ childMenu.name }}</span>
                          <span style="color: #666; font-size: 0.375rem">{{ ` (已选${checkedList?.[childMenu.key]?.length || 0})` }}</span>
                        </div>
                      </div>
                    </template>
                    <a-checkbox-group
                      class="checkbox-wrapper"
                      v-model:value="checkedList[childMenu.key]"
                      v-show="!getCollapse(childMenu.key)"
                      @change="
                        checkedValue => {
                          onCheckChange(checkedValue, menu.key, childMenu.key);
                        }
                      "
                    >
                      <a-row>
                        <a-col :span="8" v-show="!info.hide" v-for="info in childMenu.info" :key="info.columnKey">
                          <a-checkbox v-model:value="info.columnKey" :disabled="isDisabled(info)" v-if="showItem(info)">
                            <a-tooltip>
                              <template #title> {{ getTitle(info) }} </template>
                              <span class="textHide check-label">{{ info.columnValue }}</span>
                            </a-tooltip>
                          </a-checkbox>
                        </a-col>
                      </a-row>
                    </a-checkbox-group>
                  </a-card>
                </div>
              </a-card>
            </a-col>
            <a-col :span="18" class="customize-list-empty" v-if="!loadCheckBox">
              <a-spin style="width: 100%; height: 100%; padding: 50%" size="large"></a-spin>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="5" class="customize-right pt20">
          <span class="customize_title font18 weight pl10">已选{{customizeTitle}}({{ draggableList.length + fixedList.length }})<span class="clear_all pr10 cursorPoi" @click="clearAll">清空</span></span>
          <div class="font14 pl10 mb10" style="color: rgba(0, 0, 0, 0.45)">拖动可自定义{{customizeTitle}}顺序</div>
          <div v-if="fixedList.length > 0">
            <div class="draggable-box" v-for="(item, index) in fixedList" :key="index">
              <div class="flexJustifyBetween flexAlignCenter">
                <MenuOutlined class="mr10" />
                <div style="word-break: break-all">{{ item.label }}</div>
              </div>
            </div>
            <a-divider><span style="color: rgba(0, 0, 0, 0.45)">以上指标将横向固定</span></a-divider>
          </div>
          <Draggable :draggable-list="draggableList" :fixed-list="fixedList" :title="customizeTitle" @getDraggableList="getDraggableList" @deleteDraggable="deleteDraggable" />
        </a-col>
      </a-row>
    </a-card>
    <div class="customize-footer flexJustifyBetween">
      <div class="footer-left flexJustifyBetween flexAlignStart"></div>
      <div class="footer-right">
        <a-button class="footer-btn cancel-btn mr20" @click="onCancelClick">取消</a-button>
        <a-button type="primary" class="footer-btn ok-btn" @click="onOkClick">确定</a-button>
      </div>
    </div>
    <a-spin :spinning="spinLoading" size="large" class="loading" />
  </a-modal>
</template>
<script>
import Draggable from './DragSort.vue';
import _ from 'lodash';
import { MenuOutlined } from '@ant-design/icons-vue';
export default {
  components: { Draggable, MenuOutlined },
  emits: ['getDraggableList', 'getTempData'],
  inject: ['getChannel'],
  props: {
    // 标题
    customizeTitle: {
      type: String,
      default: '',
    },
    // 菜单数组
    menuList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 已选指标数组
    selectedIndicator: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 弹窗开关
    modalVisible: {
      type: [Boolean, String],
      default: false,
    },
    // 固定指标
    fixedList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 是否显示选框
    showCheck: {
      type: Boolean,
      default: true,
    },
    // 标题旁说明文字
    titleTips: {
      type: String,
      default: null,
    },
  },
  watch: {
    menuList(newVal) {
      this.menuArray = _.cloneDeep(newVal);
      this.openKeys = _.cloneDeep(this.openKeyList);
    },
    selectedIndicator(newVal) {
      this.draggableList = newVal;
    },
    modalVisible(newVal, oldVal) {
      this.checkAllList = [];
      this.customizeModalVisible = newVal === 'loading' ? true : newVal;
      if (newVal === 'loading') {
        this.spinLoading = false;
        return false;
      }
      if (this.customizeModalVisible) {
        this.initModal();
        setTimeout(() => {
          this.loadCheckBox = true;
        }, 50);
      }
      if (oldVal === true && newVal === false) {
        // this.onCancelClick();
        this.spinLoading = false;
      }
    },
  },
  data() {
    return {
      loadCheckBox: false, //是否加载checkbox部分
      // 弹窗绑定值
      customizeModalVisible: false,
      // 搜索绑定值
      searchValue: '',
      // 排序数组
      draggableList: [],
      // 左侧菜单数组
      menuArray: [],
      // 菜单选中值
      selectedKeys: [],
      // 菜单展开值
      openKeys: [],
      openKeyList: [],
      // 已选择数据
      checkedList: {},
      // 全选项数组
      checkAllList: [],
      // 选择但非全选项数组
      indeterminateList: [],
      isSearch: false,
      // 是否保存为常用自定义列
      saveChecked: true,
      // 折叠的key数组
      activeKeyList: [],
      spinLoading: false,
    };
  },
  methods: {
    /** 清空所有
     * @param {*}
     * @return {*}
     */
    clearAll() {
      this.draggableList = [];
      this.menuArray.forEach(item => {
        this.openKeys.push(item.key);
        this.checkedList = {};
        this.checkAllList = [];
        this.indeterminateList = [];
        this.checkAccountId();
      });
    },
    // 是否显示指标
    showItem(info) {
      return true;
    },
    // 指标悬浮显示
    getTitle(info) {
      return info.columnValue;
    },
    /** 是否禁止选中
     * @param {*} info
     * @return {*}
     */
    isDisabled(info) {
      let index = this.fixedList.findIndex(item => {
        return item.key === info.columnKey;
      });
      return index !== -1 || info.disabled;
    },
    /** 菜单栏收缩变化
     * @param {*} type
     * @return {*}
     */
    openKeyChange(type) {
      this.openKeys = _.cloneDeep(this.openKeyList);
    },
    /** 初始化弹窗
     * @param {*}
     * @return {*}
     */
    initModal() {
      this.checkedList = {};
      this.menuArray = _.cloneDeep(this.menuList);

      this.openKeys = Array.isArray(this.openKeys) ? this.openKeys : [];
      this.menuArray.forEach(item => {
        this.openKeys.push(item.key || item.columnKey);
      });
      this.openKeyList = _.cloneDeep(this.openKeys);
      this.selectedKeys = this.menuArray.length > 0 ? [this.menuArray[0] && this.menuArray[0].key] : [''];
      // 将传递来的已选列表与已选中列表进行对应
      const _this = this;
      this.selectedIndicator &&
        this.selectedIndicator.forEach(item => {
          _this.checkedList[item.info?.[0]?.info ? item.twoColumnKeys : item.oneColumnKeys] = _this.checkedList[item.info?.[0]?.info ? item.twoColumnKeys : item.oneColumnKeys] || [];
          if (!_this.checkedList[item.info?.[0]?.info ? item.twoColumnKeys : item.oneColumnKeys].includes(item.columnKey)) {
            _this.checkedList[item.info?.[0]?.info ? item.twoColumnKeys : item.oneColumnKeys].push(item.columnKey);
          }
          let index = _this.getArrayKeyIndex(_this.menuArray, item.oneColumnKeys);
          if (index !== -1 && item.info?.[0]?.info) {
            let childIndex = _this.getArrayKeyIndex(_this.menuArray[index].info, item.twoColumnKeys);
            if (_this.menuArray?.[index]?.info[childIndex]) {
              _this.setCheckAll(_this.checkedList[item.twoColumnKeys], _this.menuArray[index].info[childIndex].info, item.twoColumnKeys);
            }
          } else if (index !== -1) {
            if (_this.menuArray?.[index]?.info) {
              // 去重
              const uniqueList = [...new Set(_this.checkedList[item.oneColumnKeys])];
              _this.setCheckAll(uniqueList, _this.menuArray[index].info, item.oneColumnKeys);
            }
          }
        });
      this.draggableList = this.selectedIndicator;
      this.draggableList = this.unique(this.draggableList);
      this.checkAccountId();
    },
    /** 搜索
     * @param {string} value 搜索的值
     * @return {*}
     */
    onSearchValue(value) {
      if (value) {
        this.isSearch = true;
        this.menuArray.forEach((item, index) => {
          if (item.info?.[0]?.info) {
            item.info.forEach((childItem, childIndex) => {
              childItem.info.forEach(info => {
                info.hide = true;
                if (info.columnValue.indexOf(value) !== -1) {
                  info.oneColumnKeys = item.key;
                  info.hide = false;
                }
              });
            });
          } else {
            item.info.forEach(info => {
              info.hide = info.columnValue.indexOf(value) === -1;
            });
          }
        });
      } else {
        this.isSearch = false;
        this.menuArray = _.cloneDeep(this.menuList);
      }
    },
    /** 获取指定值在数组中的索引
     * @param {String} arr 查询的数组
     * @param {String} key 值
     * @return {*}
     */
    getArrayIndex(arr, key) {
      return arr.indexOf(key);
    },
    /** 获取指定key在数组中对应key所在的索引
     * @param {String} arr 查询的数组
     * @param {String} key
     * @return {*}
     */
    getArrayKeyIndex(arr, key) {
      let returnIndex = -1;
      arr.forEach((item, index) => {
        if (item.key === key) {
          returnIndex = index;
        }
      });
      return returnIndex;
    },
    /** 删除数组指定项
     * @param {String} arr 原数组
     * @param {String} key 要删除的key值
     * @return {*}
     */
    deleteArrayItem(arr, key) {
      return arr.filter(item => {
        return item !== key;
      });
    },
    /** 设置固定选中
     * @return {*}
     */
    checkAccountId() {
      let _this = this;
      this.menuArray.forEach(item => {
        if (item.info?.[0]?.info) {
          item.info.forEach(childItem => {
            childItem.info.forEach(info => {
              let index = this.fixedList.findIndex(item => {
                return item.key === info.columnKey;
              });
              if (index !== -1) {
                _this.checkedList[childItem.key] = _this.checkedList[childItem.key] || [];
                this.fixedList.forEach(item => {
                  _this.checkedList[childItem.key].unshift(item.key);
                });
                this.checkedList[info.twoColumnKeys] = this.unique(this.checkedList[info.twoColumnKeys]);
                this.setCheckAll(_this.checkedList[info.twoColumnKeys], childItem.info, info.twoColumnKeys);
              }
            });
          });
        } else {
          item.info.forEach(info => {
            let index = this.fixedList.findIndex(item => {
              return item.key === info.columnKey;
            });
            if (index !== -1) {
              this.fixedList.forEach(item => {
                //_this.checkedList.unshift(item.key);
                // checkedList为{}时，需手动添加
                if (Object.keys(this.checkedList).length === 0) {
                  _this.checkedList = { [info.oneColumnKeys]: [info.columnKey] };
                  _this.indeterminateList.push(info.oneColumnKeys);
                }
                if (Object.keys(this.checkedList).length > 0 && !_this.checkedList[info.oneColumnKeys].includes(item.key)) {
                  _this.checkedList[info.oneColumnKeys].push(info.columnKey);
                  _this.indeterminateList.push(info.oneColumnKeys);
                }
              });
            }
          });
        }
      });
      this.draggableList = this.draggableList.filter(item => {
        return (
          this.fixedList.findIndex(fixed => {
            return fixed.key === item.columnKey;
          }) === -1
        );
      });
      this.draggableList = _.uniqWith(this.draggableList, 'columnKey');
      Object.keys(this.checkedList)?.forEach(key => {
        this.checkedList[key] = _.uniq(this.checkedList[key]);
      });
    },

    /** 全选框变化事件
     * @param {Object} e 选框变化回调数据
     * @param {String} key 全选变化的项key值
     * @return {*}
     */
    onCheckAllChange(e, key, childKey) {
      // console.log('全选变化onCheckAllChange', e, key, childKey)
      let level = childKey ? 3 : 2;
      let index = this.getArrayKeyIndex(this.menuArray, key);
      let _this = this;
      if (level === 2) {
        if (e.target.checked) {
          _this.checkAllList.push(key);
          _this.indeterminateList = this.deleteArrayItem(this.indeterminateList, key);
          _this.menuArray[index].info.forEach(item => {
            _this.checkedList[key] = _this.checkedList[key] ? _this.checkedList[key] : [];
            _this.checkedList[key].push(item.columnKey);
            this.draggableList.push(item);
          });
        } else {
          _this.checkAllList = _this.deleteArrayItem(this.checkAllList, key);
          _this.indeterminateList = this.deleteArrayItem(this.indeterminateList, key);
          _this.checkedList[key] = [];
          _this.menuArray[index].info.forEach(item => {
            this.draggableList = this.draggableList.filter(draggable => {
              return draggable.columnKey !== item.columnKey;
            });
          });
        }
        _this.checkedList[key] = this.unique(_this.checkedList[key]);
      } else {
        let childIndex = _this.getArrayKeyIndex(_this.menuArray[index].info, childKey);
        if (e.target.checked) {
          _this.checkAllList.push(childKey);
          _this.indeterminateList = this.deleteArrayItem(this.indeterminateList, childKey);
          _this.menuArray[index].info[childIndex].info.forEach(item => {
            _this.checkedList[childKey] = _this.checkedList[childKey] ? _this.checkedList[childKey] : [];
            _this.checkedList[childKey].push(item.columnKey);

            this.draggableList.push(item);
          });
        } else {
          _this.checkAllList = _this.deleteArrayItem(this.checkAllList, childKey);
          _this.indeterminateList = this.deleteArrayItem(this.indeterminateList, childKey);
          _this.checkedList[childKey] = [];
          _this.menuArray[index].info[childIndex].info.forEach(item => {
            this.draggableList = this.draggableList.filter(draggable => {
              return draggable.columnKey !== item.columnKey;
            });
          });
        }
      }
      this.draggableList = this.unique(this.draggableList);
      this.checkAccountId();
    },

    /** 多选框组变化事件
     * @param {Array} value 多选框选中的值
     * @param {String} key 发生变化的多选框组的父级key值
     * @param {String} childKey 发生变化的多选框组的key值
     * @return {*}
     */
    onCheckChange(value, key, childKey) {
      // console.log('onCheckChange', value, key, childKey);
      let level = childKey ? 3 : 2;
      let index = this.getArrayKeyIndex(this.menuArray, key);
      setTimeout(() => {
        let _this = this;
        if (level === 2) {
          _this.setCheckAll(value, _this.menuArray[index].info, key);
          _this.checkAllList = this.unique(_this.checkAllList);
          _this.indeterminateList = this.unique(_this.indeterminateList);
          _this.menuArray[index].info.forEach(item => {
            if (value.indexOf(item.columnKey) === -1) {
              _this.draggableList = _this.draggableList.filter(draggable => {
                return draggable.columnKey !== item.columnKey;
              });
            } else {
              let flag = _this.draggableList.some(drag => {
                return drag.columnKey === item.columnKey;
              });
              if (!flag) {
                _this.draggableList.push(item);
              }
            }
          });
        } else {
          let childIndex = _this.getArrayKeyIndex(_this.menuArray[index].info, childKey);
          this.setCheckAll(value, this.menuArray[index].info[childIndex].info, childKey);
          _this.menuArray[index].info[childIndex].info.forEach(item => {
            if (value.indexOf(item.columnKey) === -1) {
              this.draggableList = this.draggableList.filter(draggable => {
                return draggable.columnKey !== item.columnKey;
              });
            } else {
              this.draggableList.push(item);
            }
          });
        }
        _this.draggableList = this.unique(this.draggableList);
      }, 100);
    },
    /** 设置全选或部分选中或不选
     * @param {Array} value1 已选中的数组
     * @param {Array} value2 对比的数组
     * @param {String} key 要添加的key
     * @return {*}
     */
    setCheckAll(value1, value2, key) {
      if (value1.length === 0) {
        this.indeterminateList = this.deleteArrayItem(this.indeterminateList, key);
        this.checkAllList = this.deleteArrayItem(this.checkAllList, key);
      } else if (value1.length === value2.length) {
        this.indeterminateList = this.deleteArrayItem(this.indeterminateList, key);
        this.checkAllList.push(key);
      } else if (value1.length < value2.length) {
        this.indeterminateList.push(key);
        this.checkAllList = this.deleteArrayItem(this.checkAllList, key);
      }
    },
    /** 去重对象数组
     * @param {Array} arr 对象数组
     * @return {*}
     */
    unique(arr) {
      let set = new Set();
      arr?.forEach(item => {
        set.add(JSON.stringify(item));
      });
      arr = [...set].map(item => {
        return JSON.parse(item);
      });
      return arr;
    },
    /** 获取排序后的已选指标数组
     * @param {Array} list 已选指标数组
     * @return {*}
     */
    getDraggableList(list) {
      this.$emit('getDraggableList', list);
    },
    /** 删除右边已选指标
     * @param {Object} value 选中的指标对象
     * @return {*}
     */
    deleteDraggable(value) {
      if (value) {
        this.checkedList[value.twoColumnKeys || value.oneColumnKeys] = this.checkedList[value.twoColumnKeys || value.oneColumnKeys].filter(item => {
          return item !== value.columnKey;
        });
        this.checkAllList = this.deleteArrayItem(this.checkAllList, value.twoColumnKeys || value.oneColumnKeys);
        if (this.checkedList[value.twoColumnKeys || value.oneColumnKeys].length > 0) {
          this.indeterminateList.push(value.twoColumnKeys || value.oneColumnKeys);
        } else {
          this.indeterminateList = this.deleteArrayItem(this.indeterminateList, value.twoColumnKeys || value.oneColumnKeys);
        }
        this.draggableList = this.draggableList.filter(item => {
          return item.columnKey !== value.columnKey;
        });
      }
    },
    /** 弹窗取消
     * @return {*}
     */
    onCancelClick() {
      console.info('弹窗取消');
      // this.$refs.columnNameRef.resetFields();
      setTimeout(() => {
        this.checkedList = {};
        this.searchValue = '';
        this.draggableList = [];
        this.selectedKeys = [];
        this.checkAllList = [];
        this.indeterminateList = [];
        this.saveChecked = true;
        this.$emit('getTempData');
      }, 200);
    },

    /** 弹窗确定
     * @return {*}
     */
    onOkClick() {
      let params = [];
      this.spinLoading = true;
      let orderNum = 0;
      let keyList = [];
      if (this.fixedList.length > 0) {
        this.menuList.forEach(data => {
          if (data.info.length > 0) {
            data.info.forEach(item => {
              let index = this.fixedList.findIndex(fixedItem => {
                return fixedItem.key === item.columnKey;
              });
              if (index !== -1) {
                orderNum++;
                keyList.push(item.columnKey);
                params.push(
                  {
                    customConfigId: item.customConfigId,
                    columnKey: item.columnKey,
                    columnValue: item.columnValue,
                    orderNum: orderNum,
                    isCommonly: 1,
                  },
                );
              }
            });
          }
        });
      }
      this.draggableList.forEach((item, index) => {
        if (keyList.includes(item.columnKey)) {
          return false;
        }
        params.push(
          {
            customConfigId: item.customConfigId,
            columnKey: item.columnKey,
            columnValue: item.columnValue,
            orderNum: orderNum + index,
            isCommonly: 2,
          },
        );
      });
      if (params.length === 0) {
        this.$message.error('请至少选择一项指标');
        this.spinLoading = false;
        return false;
      }
      this.$emit('getTempData', params);
      this.spinLoading = true;
    },
    /** 判断当前索引是否在折叠数组中
     * @param {Number} index 索引
     * @return {*}
     */
    getCollapse(index) {
      return this.activeKeyList.indexOf(index) !== -1;
    },
    /** 列表选中右侧菜单
     * @param {String} key 选中的菜单key值
     * @param {String} parentKey 展开的菜单父key值
     * @return {*}
     */
    selectedKey(key, parentKey) {
      // if (parentKey) {
      //   this.openKeys = [parentKey];
      // }
      this.selectedKeys = key ? [key] : [parentKey];
    },
  },
};
</script>
<style lang="less" scoped>
.color45 {
  color: rgba(0, 0, 0, 0.45);
}
.color85 {
  color: rgba(0, 0, 0, 0.85);
}
.customize-column {
  position: relative;
  .loading {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -100px;
  }
  // .customize-top {
  //   box-shadow: 0px 6px 5px -5px #decfcf;
  // }
  .customize-content {
    height: 16.125rem;
    width: calc(100%-0.375rem);
    margin-right: 0.375rem;
    border: 1px solid #f0f0f0;
    border-radius: 0.125rem;
    .menu-list {
      height: 16rem !important;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .customize-list {
      height: 100%;
      overflow: auto;
    }
  }
  .customize-right {
    background: #f5f7fa;
    display: flex;
    flex-direction: column;
    .customize_title {
      display: flex;
      justify-content: space-between;
    }
    .clear_all {
      font-size: 14px;
      color: #197afb;
    }
    .ant-divider-horizontal.ant-divider-with-text-center::before,
    .ant-divider-horizontal.ant-divider-with-text-center::after {
      border-top: 1px solid #dadada;
      top: 0;
    }
    .draggable-box {
      width: 100%;
      min-height: 0.75rem;
      background: #fff;
      padding: 0.1875rem 0.375rem;
      margin-bottom: 0.25rem;
      display: flex;
      cursor: all-scroll;
      justify-content: space-between;
      align-items: center;
    }
  }
  .customize-footer {
    padding: 0.13rem;
    .flexAlignStart {
      align-items: center;
    }
    .footer-btn {
      width: 3rem;
    }
  }
}
</style>
<style lang="less">
.search-result-box {
  margin-right: 0.375rem;
  .ant-card-body {
    padding: 10px 24px 24px;
  }
}
.ant-menu-submenu-selected {
  .ant-menu-submenu-title {
    a {
      color: #1890ff !important;
    }
  }
}
.customize-column {
  .ant-modal-content {
    min-height: 20.31rem;
    .ant-modal-body {
      padding: 0.31rem 0.375rem 0.62rem;
      .ant-card-head {
        padding: 0;
        .ant-card-head-title {
          padding: 0;
          flex: 0.6;
          .title-tips {
            font-size: 0.375rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #4e9aff;
          }
        }
        .ant-card-extra {
          padding: 0;
          flex: 0.4;
        }
      }
      .ant-card-body {
        padding: 0.375rem 0;
        .customize-content {
          .menu-list {
            .ant-menu-item {
              margin-top: 0;
            }
            .ant-menu-item:first-child {
              border-radius: 0.125rem 0 0 0;
            }
            .ant-menu-item-selected {
              background: #197afb;
              & > a {
                color: #fff;
                font-size: 0.4375rem;
              }
            }
          }
        }

        .customize-list {
          .ant-card-head {
            min-height: 1.125rem;
            padding: 0.175rem 0.375rem 0.125rem;
            background: #f5f7fa;
          }
          .ant-card-body {
            padding: 0;
          }
          .ant-checkbox-group {
            padding: 24px 0.375rem;
          }
        }
      }
    }
  }
  .checkbox-wrapper {
    width: 100%;
    .ant-col {
      margin-bottom: 0.6315rem;
    }
    .ant-checkbox-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      .ant-checkbox + span {
        width: 100%;
      }
      .check-label {
        display: inline-block;
        width: 85%;
      }
    }
  }
  .ant-modal-close-x {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .search-result-box {
    border-radius: 0.125rem;
    .ant-card-body {
      padding: 0 !important;
    }
    .search-label {
      min-height: 1.125rem;
      padding: 0.175rem 0.375rem 0.125rem;
      background: #f5f7fa;
    }
    .search-result {
      padding: 0.375rem;
    }
  }
}
</style>