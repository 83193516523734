<template>
  <a-modal :visible="visible" title="选择账户" :body-style="{ height: '19.875rem', padding: '0.35rem 0.45rem' }" width="44rem" :ok-button-props="{ loading: pushing }" @ok="onModalConfirm" @cancel="onModalCancel">
    <div style="margin-bottom: 0.35rem">
    </div>
    <div class="flexAlignCenter">
      <a-spin :spinning="accountLoading" tip="获取账号中...">
        <div class="left">
          <div style="display: flex">
            <a-input-search class="search-input" placeholder="请输入账户id搜索，多账户用空格隔开" allow-clear v-model:value="leftSearchKey" @search="onLeftSearchEnter" @change="onLeftSearchInputChange">
            </a-input-search>
          </div>
          <div style="margin: 0 0 10px 15px;">
            <a-checkbox
              v-model:checked="checkAll"
              :indeterminate="indeterminate"
              @change="onCheckAllChange"
            >
              全选
            </a-checkbox>
          </div>
          <a-list style="overflow-y: scroll; height: 11.7rem" item-layout="vertical" :split="false">
            <a-list-item v-for="company in accountList" :key="company.id" class="list-item-level-1">
              <div style="display: flex; padding-bottom: 0.25rem">
                <a-checkbox v-model:checked="company.checked" :indeterminate="company.indeterminate" @change="onCompanyChange(company)">
                  {{ company.advertiserName }}  ({{company.advertiserId}})
                </a-checkbox>
              </div>
            </a-list-item>
          </a-list>

          <!-- 分页 -->
          <div class="pagination-wrapper" style="position: absolute;left:15px; bottom: 20px">
            <a-pagination
              v-model:current="current"
              v-model:page-size="pageSize"
              :pageSizeOptions="pageSizeOptions"
              :total="total"
              @update:pageSize="onPageSizeChange"
              @update:current="onCurrentChange"
              :show-size-changer="true"
              :show-total="total => `共 ${total} 条`"
            />
          </div>
        </div>
      </a-spin>
      <div class="right">
        <div class="header">
          <label>已选账户({{ getSelectNum }})</label>
          <a href="#" @click="clearAll">清空</a>
        </div>
        <div>
          <a-list style="overflow-y: scroll; height: 11.7rem" item-layout="vertical" :split="false">
            <a-list-item v-for="company in selectedAccountList" :key="company.id" class="list-item-level-1">
              <div class="right-account" style="padding: 0.15rem">
                {{ company.advertiserName }}  ({{company.advertiserId}})
                <a-button
                  type="link"
                  size="small"
                  class="account-close-button"
                  @click="onCompanyClear(company.id)">
                  <template #icon>
                    <CloseOutlined />
                  </template>
                </a-button>
              </div>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </div>
    <template #footer>
      <a-button key="back" @click="onModalCancel">取消</a-button>
      <a-button key="submit" type="primary" @click="onModalConfirm" class="modal-ok-button-right">确定</a-button>
    </template>
  </a-modal>
</template>

<script>
import { CloseOutlined } from '@ant-design/icons-vue';
import _ from 'lodash';

// TODO 测试数据
import mockAccountList from '../jsonData/mockAccountList.json';
import {getMediaAccountListData} from '@/api/mediaAccount/mediaAccount'

export default {
  emits: ['modalConfirm', 'modalCancel', 'update:visible'],
  components: {
    CloseOutlined
  },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    // 父组件传递的已选账户列表
    selectAccountList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      indeterminate: false,
      checkAll: false,
      accountLoading: false, //加载左侧账号列表
      accountAllList: [], // 全部账户数据
      accountList: [], // 当前账户数据
      accountListStorage: [], // 当前账户数据本地缓存
      selectedAccountList: [], // 已选中的账户
      selectedAccountListStorage: [], // 已选中的账户数据本地缓存
      leftSearchKey: '', // 左侧搜索关键字
      current: 1,
      pageSize: 10,
      total: 0,
      pageSizeOptions: [10, 20, 30, 40, 50], // 每页显示条目的选择项
      initFlag: false, // 初始化标识，用于判断是否需要加载数据
    };
  },
  watch: {
    // 监听 checkedList 的变化，更新 indeterminate 和 checkAll
    selectedAccountList(val) {
      // console.log('选中的账号', val);
      const currentSelectedVal = [];
      this.accountList.forEach(item => {
        val.forEach(item2 => {
          if (item.id === item2.id) {
            currentSelectedVal.push(item);
          }
        });
      });
      this.indeterminate = !!currentSelectedVal.length && currentSelectedVal.length < this.accountList.length;
      this.checkAll = currentSelectedVal.length === this.accountList.length;
    },
    // 数据源变化，更新本地缓存
    accountListStorage: {
      handler(newVal) {
        if (newVal) {
          this.selectedAccountListStorage = this.selectedAccountList = newVal.filter(company => company.checked || company.indeterminate);
        } else {
          this.selectedAccountListStorage = this.selectedAccountList = [];
        }
      },
      deep: true,
      immediate: true // 立即执行 handler 函数
    },
    visible(newVal) {
      if (newVal) {
        this.selectedAccountList = _.cloneDeep(this.selectAccountList);
        this.selectedAccountList.forEach(item => {
          item.checked = true;
          item.indeterminate = false;
          const index = this.accountAllList.findIndex(allItem => {
            return item.id === allItem.id;
          });
          if (index === -1) {
            this.accountAllList.push(item);
          }
        });
        this.selectedAccountListStorage = _.cloneDeep(this.selectAccountList);
        this.accountListStorage = _.cloneDeep(this.selectedAccountList);
        // 获取账号列表
        this.getAccountList();
      }
    },
  },
  computed: {
    getSelectNum() {
      return this.selectedAccountList.length;
    },
  },
  methods: {
    // 获取账号列表
    async getAccountList() {
      this.checkAll = false;
      this.indeterminate = false;
      this.accountLoading = true;
      let params = {
        mediaChannel: "TT",
        page: this.current.toString(),
        limit: this.pageSize.toString(),
        mdaIds: this.leftSearchKey,
      };
      //调用
      let res = await getMediaAccountListData(params)
      if(res.code === 0){
        this.accountList = res.page.list;
        // 赋值id
        this.accountList.forEach(item => {
          item.id = item.advertiserId;
        });
        this.total = res.page.totalCount;
      }

      // 当前页已勾选账号数
      let num = 0;
      this.accountList.forEach(item => {
        item.checked = false;
        item.indeterminate = false;

        const index = this.accountAllList.findIndex(allItem => {
          return item.id === allItem.id;
        });
        if (index === -1) {
          this.accountAllList.push(item);
        }

        const index1 = this.selectedAccountList.findIndex(selectItem => {
          return item.id === selectItem.id;
        });
        if (index1 === -1) {
          item.checked = false;
        }

        if (item.checked) {
          this.onCompanyChange(item);
        }

        // 处理勾选回显
        if (this.selectedAccountListStorage.length > 0) {
          const index = this.selectedAccountListStorage.findIndex(item1 => item1.id === item.id);
          if (index !== -1) {
            num++;
            item.checked = true;
          }
        }
      });
      // 全选状态
      this.indeterminate = !!num && num < this.accountList.length;
      this.checkAll = num === this.accountList.length;
      this.accountLoading = false;
      /** end-----模拟测试------------- */

      // TODO 接口对接
      /*getAccountListApi(params).then(res => {
        if (res.code === 0 && res.list && res.list.length > 0) {
          this.accountList = res.data.list;
          this.current = res.data.currPage;
          this.pageSize = res.data.pageSize;
          this.total = res.data.totalCount;
          // 当前页已勾选账号数
          let num = 0;
          this.accountList.forEach(item => {
            item.checked = false;
            item.indeterminate = false;
            // 将分页账号添加到全部账号列表中
            const index = this.accountAllList.findIndex(allItem => {
              return item.id === allItem.id;
            });
            if (index === -1) {
              this.accountAllList.push(item);
            }
            // 处理已选账号
            const index1 = this.selectedAccountList.findIndex(selectItem => {
              return item.id === selectItem.id;
            });
            if (index1 === -1) {
              item.checked = false;
            }

            if (item.checked) {
              this.onCompanyChange(item);
            }

            // 处理勾选回显
            if (this.selectedAccountListStorage.length > 0) {
              const index = this.selectedAccountListStorage.findIndex(item1 => item1.id === item.id);
              if (index !== -1) {
                num++;
                item.checked = true;
              }
            }
          });
          // 全选状态
          this.indeterminate = !!num && num < this.accountList.length;
          this.checkAll = num === this.accountList.length;

          setTimeout(() => {
            if (this.initFlag) {
              this.getSelectAccountList();
            }
          }, 100);
        }
        this.accountLoading = false;
      });*/
    },
    // 处理 pageSize 改变的逻辑
    onPageSizeChange(newPageSize) {
      this.pageSize = newPageSize;
      this.getAccountList();
    },
    // 处理 current 改变的逻辑
    onCurrentChange(newCurrent) {
      this.current = newCurrent;
      this.getAccountList();
    },
    // 全选
    onCheckAllChange(e) {
      this.accountList.forEach(company => {
        company.checked = e.target.checked;
        this.accountAllList.forEach(item => {
          if(item.id === company.id) {
            item.checked = e.target.checked;
          }
        });
        // 处理
        this.onCompanyChange(company);
      });
      this.checkAll = e.target.checked;
      this.indeterminate = !e.target.checked;
    },
    // 搜索框变化
    onLeftSearchInputChange() {
      // console.log('搜索', this.leftSearchKey);
    },
    // 左侧搜索
    onLeftSearchEnter() {
      this.current = 1;
      this.getAccountList();
    },
    // 选中
    onCompanyChange(val) {
      const id = val.id;
      const currentChecked = val.checked;
      this.accountAllList.forEach(item => {
        if (item.id === id) {
          item.checked = currentChecked;
          item.indeterminate = false;
        }
      });
      const company = this.accountAllList.find(item => item.id === id);
      const checked = company.checked;
      company.indeterminate = false;
      company.checked = checked;
      if (!checked) {
        // 已选择 则取消
        this.accountListStorage = this.accountListStorage.filter(company => company.id !== id);
      } else {
        // 未选择 则选中 添加
        if (!this.accountListStorage.find(company => company.id === id)) {
          this.accountListStorage.push(company);
        }
      }
    },
    // 清空所选
    clearAll() {
      this.accountList.forEach(company => {
        company.checked = false;
        company.indeterminate = false;
      });

      this.accountAllList.forEach(company => {
        company.checked = false;
        company.indeterminate = false;
        // 处理
        this.onCompanyChange(company);
      });

      this.accountListStorage.forEach(company => {
        company.checked = false;
        company.indeterminate = false;
        // 处理
        this.onCompanyChange(company);
      });

      this.selectedAccountList = [];
      this.selectedAccountListStorage = [];
    },
    // 删除
    onCompanyClear(id) {
      const company = this.accountList.find(company => company.id === id);
      const checked = company.checked;
      company.indeterminate = false;
      company.checked = !checked;
      if (checked) {
        // 已选择 则取消
        this.accountListStorage = this.accountListStorage.filter(company => company.id !== id);
      } else {
        // 未选择 则选中 添加
        this.accountListStorage.push(company);
      }
    },
    async onModalConfirm() {
      if (this.selectedAccountList.length === 0) {
        this.$message.warn('请至少选择一个账号');
        return;
      }
      // 提取部分字段
      let params = this.selectedAccountList.map(item => {
        return {
          id: item.advertiserId,
          advertiserId: item.advertiserId,
          advertiserName: item.advertiserName,
          mediaChannel: item.mediaChannel,
          bz: item.bz,
          tiasCustomerReportId: item.tiasCustomerReportId,
        };
      });
      this.$emit('confirm-data', params);
      this.$emit('update:visible', false);
    },
    onModalCancel() {
      this.beforeModalClose();
      this.$emit('modalCancel');
    },
    // 关闭弹窗
    beforeModalClose() {
      this.initialData();
      this.$emit('update:visible', false);
    },
    // 初始化数据
    initialData() {
      this.accountList = [];
      this.accountAllList = [];
      this.accountListStorage = [];
      this.selectedAccountList = [];
      this.selectedAccountListStorage = [];
      this.leftSearchKey = '';
    },
  },
};
</script>

<style lang="less" scoped>
.left,
.right {
  flex: 1;
  width: 21.25rem;
  height: 17.625rem;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 4px;

  .header {
    background: #f5f7fa;
    height: 1.125rem;
    display: flex;
  }

  .search-input {
    margin: 0.375rem;
    flex: 1;
  }

  .ant-list-item {
    width: auto;
    margin: 0 0.68rem;
    padding: 0.25rem 0;

    .list-item-level-2 {
      position: relative;
      width: auto;
    }

    .label-text {
      margin-left: 0.5em;
    }
  }
}

.left {
  .header {
    .header-tab {
      position: relative;
      font-size: 0.4375rem;
      line-height: 1.125rem;
      flex: 1;
      text-align: center;
      height: 1.125rem;
      margin: 0 8px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .header-tab-disabled {
      color: #c2c2c2;
    }

    .tab-indicator {
      color: #2f70f4;
      border-bottom: solid 3px #2f70f4;
      width: 0%;
      height: 2px;
      transition: all 0.2s ease;
    }

    .tab-indicator-show {
      width: 88%;
    }
  }
}

.right {
  margin-left: 0.75rem;
  .header {
    height: 2rem;
    align-items: center;
    padding: 0 0.35rem;
    label {
      flex: 1;
    }
    a {
      flex: 1;
      text-align: right;
    }
  }
}
.right-account:hover {
  background-color: rgb(242,242,242);
  cursor: pointer;
}
/* 鼠标悬停时的样式 */
.account-close-button:hover {
  color: #1890ff; /* 改变图标颜色，您可以使用任何颜色 */
}

.account-close-button {
  right: 20px;
  position: absolute;
  color: rgb(0, 0, 0);
  margin-top: -2px;
}
</style>