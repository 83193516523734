<script>
import { h } from 'vue';
import CustomerReportsTable from './CustomerReportsTable.vue';
export default {
  provide() {
    return {
    };
  },
  inject: ['getActiveKey'],
  render() {
    return h(this.map.get(this.getActiveKey()), { columns: this.columns[this.getActiveKey()] });
  },
  setup() {
    const map = new Map();
    map.set('customerReports', CustomerReportsTable);
    // 表格列
    const columns = {
      customerReports: [
        // { dataIndex: 'switch', title: '订阅', fixed: 'left', width: 50 },
        { dataIndex: 'subStatus', title: '订阅', fixed: 'left', width: 50 },
        { dataIndex: 'id', title: '报表ID' },
        { dataIndex: 'reportName', title: '报表名称' },
        { dataIndex: 'reportTypeName', title: '报表类型' },
        { dataIndex: 'mediaChannel', title: '媒体' },
        { dataIndex: 'subEndDate', title: '订阅到期时间' },
        { dataIndex: 'reportFormat', title: '报表格式' },
        // { dataIndex: 'reportFormatDetail', title: '报表格式' },
        { dataIndex: 'createTime', title: '创建时间' },
        { dataIndex: 'userName', title: '创建人' },
        { dataIndex: 'operate', title: '操作', width: 160 },
      ],
    };

    return { map, columns };
  },
  methods: {
  },
};
</script>

<style>
</style>