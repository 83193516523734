<!--
 * 订阅页面
-->
<template>
  <div class="subscribe-wrapper w100 h100">
    <div v-show="!showCustomizeReport.visible" class="w100 h100" style="padding: 12px">
      <a-tabs v-model:activeKey="activeKey" @change="activeKeyChange">
        <a-tab-pane key="customerReports" tab="客户报表"></a-tab-pane>
        <a-tab-pane key="manageReports" tab="管理报表" force-render></a-tab-pane>
      </a-tabs>
      <!--  列表  -->
      <SubscribeTable ref="subscribe" @showCustomizeReport="handleShowCustomizeReport"></SubscribeTable>
    </div>
    <!--  新增、编辑表单 注入：showCustomizeReport  -->
    <ReportsForm v-if="showCustomizeReport.visible" :showCustomizeReport="showCustomizeReport"  ></ReportsForm>
  </div>
</template>

<script>
import SubscribeTable from './components/SubscribeTable.vue';
import ReportsForm from '@/views/subscribe/components/ReportsForm.vue';
// 模拟数据
// import tableList from './jsonData/mockTableList.json';
import { getCustomerReportDetailById, getCustomerReportTableList,
  updateReportsStatus ,deleteReports,doReportsAPI } from '@/api/report/index.js';

export default {
  components: { ReportsForm, SubscribeTable },
  provide() {
    return {
      // 选中tab
      getActiveKey: () => this.activeKey,
      showCustomizeReport: this.showCustomizeReport,
      // 是否打开新增规则模板
      addTempVisible: this.addTempVisible,
      // 筛选组件类型
      filterType: 'home',
      // 表格数据
      tableData: this.tableData,
      // 表格配置
      table: this.table,
      // 选中表格行
      getSelectRow: () => this.selectRow,
      // 选中的模板
      getActiveTemp: () => this.activeTemp,
      // 获取表格数据
      getReportsTableData: this.getReportsTableData,
      // 编辑报表
      editReports: this.editReports,
      // 删除报表
      deleteReports: this.deleteReports,
      doReports: this.doReports,
      // 启停报表
      updateReportStatusFun: this.updateReportStatusFun,
      // 所有用户
      getAllUsers: () => this.allUsers,
    };
  },
  computed: {},
  data() {
    return {
      loginUserId : this.$store.state.oauth.userInfo.userId,
      loginGrades : this.$store.state.oauth.userInfo.grades,
      // tab页选中tab
      activeKey: 'customerReports',
      // 是否打开新增规则模板弹窗
      addTempVisible: { visible: false },
      // 记录上一次的筛选
      filterOptions: {},
      showCustomizeReport: {
        visible: false,
        refresh: false,
        mode: 'add',
      },
      //表格数据
      tableData: {
        dataSource: [],
        tableLoading: false,
      },
      // 选中表格行
      selectRow: {},
      // 表格配置
      table: {
        bordered: true,
        size: 'small',
        scroll: { x: 'max-content' },
        showExpanded: false,
        pagination: { current: 1, page: 1, pageSize: 20, total: 0, showTotal: total => `总计${total}条`, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50'] },
      },
      // 选中的模板
      activeTemp: {},
      allUsers: [],
    };
  },
  async created() {
    //模拟数据
    // this.tableData.dataSource = tableList;

    // TODO API获取报表表格数据
    this.getReportsTableData();
    //await this.getUserList();
  },
  watch: {
    'showCustomizeReport.refresh'(val) {
      if (val) {
        this.getReportsTableData();
      }
    },
    showCustomizeReport: {
      handler(newVal) {
        if (newVal && newVal.visible) {
          console.log('监听visible', newVal.visible);
        }
      },
      deep: true,
    },
  },
  methods: {
    /**
     * @description: 打开新增弹窗
     * @param {*} data
     * @return {*}
     */
    handleShowCustomizeReport(data) {
      //this.showCustomizeReport = { ...this.showCustomizeReport, ...data };
      this.showCustomizeReport.mode = data.mode;
      this.showCustomizeReport.visible = data.visible;
    },
    /**
     * @description: tab页改变
     * @return {*}
     */
    activeKeyChange() {
      this.table.pagination.page = 1;
      this.table.pagination.current = 1;
      if (this.activeKey === 'customerReports') {
        this.getReportsTableData();
      }
      this.selectRow = {};
    },
    /**
     * @description: 获取报表表格数据
     * @param {*} filterOptions
     * @return {*}
     */
    async getReportsTableData(filterOptions) {
      console.log('getReportsTableData', filterOptions);
      try {
        filterOptions ? (this.filterOptions = filterOptions) : (filterOptions = this.filterOptions);
        const postData = {
          ...filterOptions,
          // level: this.activeKey,
          size: this.table.pagination.pageSize,
          current: this.table.pagination.current,
        };
        postData.userId = this.loginUserId;
        this.tableData.tableLoading = true;
        //模拟数据
        // this.tableData.dataSource = tableList;
        // this.table.pagination.current = filterOptions.page;
        // this.table.pagination.pageSize = filterOptions.limit;
        // this.table.pagination.total = tableList.length;
        // this.tableData.tableLoading = false;

        // TODO API表格请求接口
        let res =  await getCustomerReportTableList(postData);
        if (res.code == 0) {
            this.tableData.dataSource = res.data?.records;
            this.tableData.tableLoading = false;
            this.table.pagination.current = res.data?.current;
            this.table.pagination.pageSize = res.data?.size;
            this.table.pagination.total = res.data?.total;
          }

        // const getUrlList = {
        //   customerReports: getCustomerReportTableList,
        //   manageReports: getManageReportsReportTableList,
        // };
        // let res = await getUrlList[this.activeKey](postData);
        // if (res.code == 0) {
        //   this.tableData.dataSource = res.data?.list;
        //   this.tableData.tableLoading = false;
        //   this.table.pagination.current = res.data?.currPage;
        //   this.table.pagination.pageSize = res.data?.pageSize;
        //   this.table.pagination.total = res.data?.totalCount;
        // }
        this.tableData.tableLoading = false;
      } catch (err) {
        this.tableData.tableLoading = false;
      }
      this.showCustomizeReport.refresh = false;
    },
    /**
     * @description: 编辑报表
     * @param {Object} record 点击的行数据
     * @return {*}
     */
    async editReports(record) {
      // if(this.loginGrades !== 'MANAGER' && record.userId !== this.loginUserId ){
      //   //提示不能修改
      //   this.$message.error('您不能修改其他人创建的报表');
      //   return;
      // }
      this.showCustomizeReport.mode = 'edit';
      this.selectRow = record;
      let detail = await getCustomerReportDetailById(record.id)
      this.selectRow = detail.data;
      this.getTempData(this.selectRow);
    },
    /**
     * @description: 获取从模板创建的模板数据
     * @param {*} temp
     * @return {*}
     */
    getTempData(temp) {
      this.activeTemp = temp;
      this.showCustomizeReport.visible = true;
    },
    /**
     * @description: 删除报表
     * @param {Object} row 点击的行数据
     * @return {*}
     */
    async deleteReports(row) {
      // if(this.loginGrades !== 'MANAGER' && row.userId !== this.loginUserId ){
      //   //提示不能修改
      //   this.$message.error('您不能删除其他人创建的报表');
      //   return;
      // }
      this.selectRow = row;
      const postData = row.id;
      let res = await deleteReports(postData);
      if(res && res.code === 0){
        this.$message.success('删除成功');
      }else {
        this.$message.error(res.msg);
      }
      this.getReportsTableData();
    },
    async doReports(row) {
      this.selectRow = row;
      const postData = row.id;
      let res = await doReportsAPI(postData);
      if(res && res.code === 0){
        this.$message.success('执行成功');
      }else {
        this.$message.error(res.msg);
      }
      this.getReportsTableData();
    },
    /**
     * @description: 启停订阅
     * @param {*} changed
     * @param {*} e
     * @param {*} record
     * @return {*}
     */
    async updateReportStatusFun(changed, e, record) {
      console.log('updateReportStatusFun', changed);
      this.selectRow = record;
      try {
        const postData = {
          id: this.selectRow.id,
          subscribeType: changed ? '已订阅' : '未订阅',
        };
        // TODO 接口对接
        let res = await updateReportsStatus(postData);
        this.$message.success(`订阅状态${changed ? '启动' : '停止'}成功！`);
        this.getReportsTableData(this.filterOptions);
      } catch (err) {

      }
    },
  },
};
</script>

<style lang="less" scoped>
.subscribe-wrapper {
  background: #fff;
  border-radius: 8px;
  :deep(.ant-tabs) {
    margin: 0 -12px;
    margin-bottom: 10px;
    padding-left: 36px;
    border-bottom: 2px solid #f5f7fa;
    .ant-tabs-bar {
      margin-bottom: 0px;
      border-bottom: none;
      .ant-tabs-extra-content {
        line-height: 0;
      }
    }
    .ant-tabs-tab {
      padding: 0 5px 12px;
    }
    .ant-tabs-ink-bar {
      height: 3px;
    }
  }
}
</style>