<!--

-->
<template>
  <div class="customize-report-wrapper w100 h100">
    <div class="report-header">
      <a-button type="link" size="large" class="report-header-close-button" @click="showCustomizeReport.visible = false"><ArrowLeftOutlined /></a-button>
      <span class="vertical-line"></span>
      <span class="header-text">{{ showCustomizeReport.mode === 'edit' ? '编辑' : '新增' }}报表</span>
    </div>
    <ul class="report-body w100 h100">
      <li class="report-module hosting-range">
        <div class="module-content">
          <ul class="report-list">
            <!-- 报表名称 -->
            <li class="report-item">
              <a-space>
                <div class="report-item-label">报表名称</div>
                <a-input v-model:value="options.reportName" allow-clear style="width: 528px" :maxlength="30" :suffix="`${options.reportName?.length ?? 0}/30`"> </a-input>
              </a-space>
            </li>
            <!-- 数据维度 -->
<!--            <li class="report-item">
              <a-space>
                <div class="report-item-label">数据维度</div>
                <a-radio-group button-style="solid" v-model:value="options.dimension" style="line-height: unset" @change="onDimensionChange">
                  <a-radio :value="item.value" v-for="(item, index) in optionList.dimensionOptions" :key="index">{{ item.label }}</a-radio>
                </a-radio-group>
              </a-space>
            </li>-->
            <!-- 报表类型 -->
            <li class="report-item">
              <a-space>
                <div class="report-item-label">报表类型</div>
                <a-select v-model:value="options.reportTypeName" allow-clear style="width: 238px" :options="optionList.reportType" v-bind="options.reportType" @change="reportTypeChange"> </a-select>
              </a-space>
            </li>
            <!-- 媒体 -->
            <li class="report-item">
              <a-space>
                <div class="report-item-label">媒体</div>
                <a-select v-model:value="options.mediaChannel" allow-clear style="width: 238px" :options="optionList.media" v-bind="options.media" @change="mediaChange"> </a-select>
              </a-space>
            </li>
            <!-- 数据口径 -->
            <li class="report-item" v-if="dataCaliberFlag">
              <a-space>
                <div class="report-item-label">数据口径</div>
                <a-select v-model:value="options.dataCaliber" allow-clear style="width: 238px" :options="optionList.dataCaliber" v-bind="options.dataCaliber" @change="dataCaliberChange"> </a-select>
              </a-space>
            </li>
            <li class="report-module">
              <a-space>
                <div class="report-item-label">选择账户</div>
                <a-button
                  style="margin-left: -15px"
                  type="link"
                  @click="addSubscribeAccount">
                  <template #icon>
                    <PlusCircleOutlined />
                  </template>
                  选择订阅账户
                </a-button>
                <span style="color: rgb(139, 139, 139)">
                  已选 <span style="color: #4e9aff">{{ accountCount }}</span> 个账户
                </span>
              </a-space>
            </li>
            <!-- 账户信息链接 -->
            <li class="report-module">
              <a-space>
                <div class="report-item-label">账户信息链接</div>
                <a-input v-model:value="options.feishuExcelUrl" allow-clear style="width: 528px"> </a-input>
              </a-space>
            </li>
            <!-- 自定义列 选择维度-->
            <li class="report-module">
              <a-space>
                <div class="report-item-label">自定义列</div>
                <!-- 选择维度 -->
                <a-button
                  type="link"
                  class="target-button"
                  @click="selectDimension">
                  <template #icon>
                    <FundOutlined />
                  </template>
                  选择维度
                </a-button>
                <span style="color: rgb(139, 139, 139)">
                  已选 <span style="color: #4e9aff">{{ dimensionCount }}</span> 个维度
                </span>
              </a-space>
            </li>
            <!-- 自定义列 选择指标-->
            <li class="report-module">
              <a-space>
                <div class="report-item-label"></div>
                <!-- 选择指标 -->
                <a-button
                  type="link"
                  class="target-button"
                  @click="selectTarget">
                  <template #icon>
                    <UnorderedListOutlined style="transform: rotate(90deg);"/>
                  </template>
                  选择指标
                </a-button>
                <span style="color: rgb(139, 139, 139)">
                  已选 <span style="color: #4e9aff">{{ targetCount }}</span> 个指标
                </span>
              </a-space>
            </li>
            <!-- 报表示例 -->
            <li class="report-module" v-if="dimensionCount > 0 || targetCount > 0">
              <a-space>
                <div class="report-item-label">报表示例</div>
                <!-- 表格 -->
                <a-table style="max-width: 1440px" bordered :data-source="reportExampleData" :scroll="{ x: 600 }" row-key="id" :pagination="false">
                  <a-table-column align="center" width="120px" v-for="col in selectedColumns" :key="col.columnKey" :title="col.columnValue" />
                </a-table>
              </a-space>
            </li>
            <!-- 订阅状态 -->
            <li class="report-module">
              <a-space>
                <div class="report-item-label">订阅状态</div>
                <a-switch :checked="options.subStatus === '已订阅'" @click="subStatusClick" />
              </a-space>
            </li>
            <!-- 订阅周期 -->
            <li class="report-module">
              <a-space>
                <div class="report-item-label">订阅周期</div>
                <a-range-picker
                  v-model:value="subscribeTime"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  :ranges="ranges"
                  :default-value="defaultSubscribeTime"
                  :placeholder="['开始时间', '结束时间']"
                  separator="-"
                  @change="onChange"
                  @ok="onOk"
                />
              </a-space>
            </li>
            <!-- 推送时间 - 时间内整点推送 -->
            <li class="report-module">
              <!-- 时报 -->
              <a-space v-if="true">
                <div class="report-item-label">推送时间</div>
                <a-input v-model:value="pushTimesString" style="width: 528px" placeholder="请输入推送时间,多个以英文逗号隔开"> </a-input>
                <a href="https://cron.ciding.cc/" target="_blank">配置示例</a>

                <!--                <a-radio-group v-model:value="options.pushTimeType" @change="pushTimeTypeChange">
                                  <a-radio :value="1">
                                    时间内整点推送
                                    <a-time-picker
                                      v-if="options.pushTimeType === 1"
                                      v-model:value="options.customPushHourStartTime"
                                      format="HH:mm"
                                      value-format="HH:mm"
                                      :minute-step="60"
                                      placeholder="选择开始时间"
                                      style="width: 120px"
                                      @change="changePushHourStartTime"
                                    ></a-time-picker>
                                    <span v-if="options.pushTimeType === 1" style="margin: 0 8px">-</span>
                                    &lt;!&ndash; 结束时间 &ndash;&gt;
                                    <a-time-picker
                                      v-if="options.pushTimeType === 1"
                                      v-model:value="options.customPushHourEndTime"
                                      format="HH:mm"
                                      value-format="HH:mm"
                                      :minute-step="60"
                                      placeholder="选择结束时间"
                                      @change="changePushHourEndTime"
                                    ></a-time-picker>
                                  </a-radio>
                                  <a-radio :value="2">自定义推送时间</a-radio>
                                </a-radio-group>-->
                <a-tooltip placement="topRight">
                  <template #title>
                    <span>数据一般会在推送时间30min内发出，受媒体接口性能影响可能会有波动。</span>
                  </template>
                  <ExclamationCircleOutlined />
                </a-tooltip>
              </a-space>
              <!-- 日报 reportType === 2 -->
              <a-space v-if="false">
                <div class="report-item-label">推送时间</div>
                                <a-time-picker
                                  v-model:value="options.customPushTime"
                                  format="HH:mm"
                                  value-format="HH:mm"
                                  placeholder="选择时间"
                                  @change="changeCustomPushTimeForDay"
                                ></a-time-picker>
              </a-space>
              <!-- 周报  reportType === 3 -->
              <a-space v-if="false">
                <div class="report-item-label">推送时间</div>
                <a-select v-model:value="options.weekly" allow-clear style="width: 128px" :options="optionList.weekly" v-bind="options.weekly" @change="weeklyChange"> </a-select>
                <a-time-picker
                  v-model:value="options.customPushTime"
                  format="HH:mm"
                  value-format="HH:mm"
                  placeholder="选择时间"
                  @change="changeCustomPushTimeForWeekly"
                ></a-time-picker>
              </a-space>
              <!-- 月报 reportType === 4 -->
              <a-space v-if="false">
                <div class="report-item-label">推送时间</div>
                <a-input v-model:value="options.pushTimes" style="width: 528px" placeholder="请输入推送时间"> </a-input>
                <a-date-picker
                  v-model:value="options.customPushTime"
                  :show-time="{ format: 'HH:mm' }"
                  format="YYYY-MM-DD HH:mm"
                  value-format="YYYY-MM-DD HH:mm"
                  placeholder="选择时间"
                  @change="onChangeForMonthly"
                  @ok="onOkForMonthly"
                />
              </a-space>
            </li>
            <!-- 时报 - 自定义推送时间 -->
            <li class="report-module" style="margin-left: 88px">
              <div class="report-item-label" style="margin-left: 80px"></div>
              <!-- 时间选择控件列表 -->
              <div v-if="options.pushTimeType === 2">
                <a-row v-for="(time, index) in customPushTimes" :key="index" gutter={16}>
                  <a-col :span="6">
                    <a-time-picker
                      v-model:value="customPushTimes[index]"
                      format="HH:mm"
                      value-format="HH:mm"
                      placeholder="选择时间"
                      @change="changeCustomPushTimeForHour(index)"
                    />
                    <a-button
                      v-if="index > 0"
                      type="link"
                      @click="removeCustomPushTime(index)">
                      <template #icon>
                        <DeleteOutlined />
                      </template>
                    </a-button>
                  </a-col>
                </a-row>
              </div>
              <!-- 添加时间按钮 -->
              <a-button
                v-if="options.pushTimeType === 2"
                type="link"
                class="target-button"
                style="width: 128px; margin-top: 4px;"
                @click="addCustomPushTime">
                <template #icon>
                  <PlusOutlined />
                </template>
                新增时间
              </a-button>
            </li>
            <!-- 报表格式 -->
            <li class="report-module">
              <a-space>
                <div class="report-item-label">报表格式</div>
                <a-checkbox-group @change="reportFormatChange" v-model:value="reportFormatList">
                  <a-row>
                    <a-checkbox :value="'excel'">报表（xlsx）</a-checkbox>
                    <a-checkbox :value="'txt'">文本（汇总）</a-checkbox>
                  </a-row>
                </a-checkbox-group>
              </a-space>
            </li>
            <!-- 账户信息链接 dataWriteLinksFlag -->
            <li class="report-module" v-if="true">
              <a-space>
                <div class="report-item-label">数据写入链接</div>
                <a-input v-model:value="options.feishuReportExcelUrl" style="width: 528px" placeholder="请输入需将拉取数据写入的在线表格链接"> </a-input>
                <span style="color: rgb(139, 139, 139); margin-left: 8px;margin-top: 8px">请输入飞书云文档地址,并且权限设置为组织内可编辑</span>
              </a-space>
            </li>
            <!-- 推送方式 -->
            <li class="report-module">
              <a-space>
                <div class="report-item-label">推送方式</div>
                <a-checkbox-group @change="pushMethodChange" v-model:value="options.pushMethodList">
                  <a-row>
<!--                    <a-checkbox :value="1" style="margin: 10px 0 20px 0">飞书</a-checkbox>-->
                    <div v-if="pushMethodFlag" class="report-item-label" style="margin: 10px 0 20px 0px">推送群名称</div>
                    <div v-if="pushMethodFlag" style="margin: 3px 0 20px 10px">
                      <a-space>
                        <div style="margin-left: 14px">
                          <a-row  gutter={16}>
                            <a-select
                              v-model:value="groupNames"
                              style="width: 328px"
                              placeholder="请选择用于接收报表的飞书群"
                              :options="feishuChatsList"
                              show-search
                              mode="multiple"
                              :filter-option="(input, option) => option.label.toLowerCase().includes(input.toLowerCase())"
                              option-filter-prop="label"
                              :max-tag-count="3"
                              :max-tag-text-length="5"
                              :max-tag-ellipsis="true"
                            />
                            <span style="color: rgb(139, 139, 139); margin-left: 8px;margin-top: 8px">请将:泰坦报表机器人 加入群聊中</span>
                          </a-row>
<!--                          <a-button type="link" style="margin-left: 8px" @click="pushTestIndex(index)">推送测试</a-button>
                            -->
<!--
                            <a-button v-if="index > 0" type="link" style="margin-left: -10px" @click="delPushTest(index)">删除</a-button>
-->
<!--
                            <a-button type="link" class="target-button" style="width: 128px; margin-top: 4px;" @click="addPushGroupName">
                              <template #icon><PlusOutlined /></template>新增群名称
                            </a-button>-->
                        </div>

                      </a-space>

                    </div>
                  </a-row>
                  <a-row>
<!--                    <a-checkbox :value="2" style="margin: 10px 0 20px 0">飞书人员</a-checkbox>-->
                    <div v-if="pushMethodForPersonFlag" class="report-item-label" style="margin: 10px 0 20px 0px">推送人员名称</div>
                    <div v-if="pushMethodForPersonFlag" style="margin: 3px 0 20px 25px">
                      <a-space>
                        <div style="margin-left: 0">
                          <a-row  gutter={16}>
                            <a-select
                              v-model:value="personNames"
                              style="width: 328px"
                              placeholder="请选择用于接收报表的飞书人员"
                              :options="feishuMembersList"
                              mode="multiple"
                              show-search
                              :filter-option="(input, option) => option.label.toLowerCase().includes(input.toLowerCase())"
                              option-filter-prop="label"
                              :max-tag-count="4"
                              :max-tag-text-length="3"
                              :max-tag-ellipsis="true"
                            />
<!--
                            <a-button type="link" style="margin-left: 8px" @click="pushFeiShuPersonTestIndex(index)">推送测试</a-button>
-->
<!--
                            <a-button v-if="index > 0" type="link" style="margin-left: -10px" @click="delPushPersonTest(index)">删除</a-button>
-->
                          </a-row>
<!--                          <a-button type="link" class="target-button" style="width: 128px; margin-top: 4px;" @click="addPushPersonName">
                            <template #icon><PlusOutlined /></template>新增人员名称
                          </a-button>-->
                        </div>
                      </a-space>
                    </div>
                  </a-row>
                </a-checkbox-group>
              </a-space>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <div class="footer">
      <a-space class="float-r" :size="23">
        <a-button style="width: 140px" @click="showCustomizeReport.visible = false">取消</a-button>
        <a-button style="width: 140px" @click="handleOk" type="primary" :loading="btnLoading">确定</a-button>
      </a-space>
    </div>

    <!--  订阅账户  -->
    <Account ref="account" v-model:visible="accountVisible" @confirm-data="handleConfirmAccount" :select-account-list="selectAccountList" :account-list="accountList"></Account>
    <!--  选择维度、指标  -->
    <CustomizeColumn :show-check="false" :customize-title="customizeTitle" :selected-indicator="selectedIndicator" :fixed-list="fixedList" :column-name="activeTemp" :menu-list="menuList" :modal-visible="modalVisible" @getDraggableList="getDraggableList" @get-temp-data="getTempData" />

  </div>
</template>

<script>
import Account from './Account.vue';
import { ExclamationCircleOutlined, DeleteOutlined, PlusOutlined, PlusCircleOutlined, FundOutlined,  UnorderedListOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue';
import CustomizeColumn from './CustomizeColumnModal.vue';
import moment from 'moment';
// json数据
import reportTypeDimensionJson from '../jsonData/reportTypeDimension.json';
// import targetData from '../jsonData/mockTargetList.json';
import targetData from '../jsonData/targetList.json';
import dimensionData from '../jsonData/mockDimensionList.json';
import { message, Modal } from 'ant-design-vue';
import _ from 'lodash';

import {getCustomerReportDetailById, getFeishuMembers, getFeishuChats, addSubscribeReport, editSubscribeReport} from '@/api/report/index.js'

export default {
  name: 'CustomizeReports',
  components: { CustomizeColumn, Account, ExclamationCircleOutlined, DeleteOutlined, PlusOutlined, PlusCircleOutlined, FundOutlined, UnorderedListOutlined, ArrowLeftOutlined },
  props: {
    reportOptions: { type: Object, default: null },
  },
  inject: ['showCustomizeReport', 'getActiveTemp', 'getAllUsers'],
  provide() {
    return {

    };
  },
  data() {
    return {
      options: {},
      tempOptions: {},
      reportConditionList: [], //触发条件下拉数据
      reportExecList: [], //执行规则下拉数据
      productLoadOver: false, //产品滚动加载是否完毕
      productList: [], //产品下拉数据
      selectedProjectList: [], //已选择的产品
      timer: null, //定时器
      // 联级选择
      selectData1: [], //联级选择第一个下拉数据
      selectData2: [], //联级选择第二个下拉数据
      //联级选择第一个选定的数据
      selectedList1: [],
      //联级选择第二个选定的数据
      selectedList2: [],
      // 确认提交loading
      btnLoading: false,
      // 下拉框选项集合
      optionList: {
        // 数据维度
        dimensionOptions: [
          { label: '客户', value: 'company' },
          { label: '产品', value: 'project' },
          { label: '部门', value: 'dept' },
          { label: '运营人员', value: 'opertor' },
          { label: '媒体账号', value: 'mdaAccount' },
        ],
        // 报表类型
        reportType: [
      /*    { value: 1, label: '时报' },*/
          { value: 2, label: '日报' },
          { value: 3, label: '周报' },
          { value: 4, label: '月报' },
        ],
        // 周报
        weekly: [
          { value: 1, label: '周一' },
          { value: 2, label: '周二' },
          { value: 3, label: '周三' },
          { value: 4, label: '周四' },
          { value: 5, label: '周五' },
          { value: 6, label: '周六' },
          { value: 7, label: '周日' },
        ],
        // 媒体渠道
        media: [
          { value: 'TT_2', label: '巨量广告2.0' },
          // { value: 'QC', label: '巨量千川' },
          // { value: 'GDT_V3', label: '腾讯广告3.0' },
          // { value: 'AQY', label: '爱奇艺' },
        ],
        // 媒体渠道
        dataCaliber: [
          { value: 1, label: '广告播放口径' },
          { value: 2, label: '转化回传口径（上报时间）' },
          { value: 3, label: '转化回传口径（激活时间）' },
        ],
      },
      // 自定义维度表头字段配置
      selectedDimensionColumns: [],
      // 自定义指标表头字段配置
      selectedTargetColumns: [],
      // 自定义表头字段配置
      selectedColumns: [],
      // 报表示例数据
      reportExampleData: [
        {
          id: 1,
          reportType: '',
          media: '',
          reportName: '',
          status: ''
        },
      ],
      // 自定义推送时间，当前选择的推送时间
      customPushTime: null,
      // 自定义推送时间 - 时间内整点推送开始时间
      customPushHourStartTime: null,
      // 自定义推送时间 - 时间内整点推送结束时间
      customPushHourEndTime: null,
      // 自定义推送时间，存储多个选择的时间
      customPushTimes: [null],
      // 选择维度数
      dimensionCount: 0,
      // 选择指标数
      targetCount: 0,
      // 推送方式
      pushMethodList: [],
      // 账户弹窗开关
      accountVisible: false,
      // 推送方式-飞书群名称
      groupNames: [],
      // 推送方式-飞书人员名称
      personNames: [],
      // 推送方式-飞书群开关
      pushMethodFlag: true, //false,
      // 推送方式-飞书人员开关
      pushMethodForPersonFlag: true, //false,
      // 数据写入链接开关
      dataWriteLinksFlag: false,
      // 维度弹窗开关
      dimensionVisible: false,
      // 指标弹窗开关
      targetVisible: false,
      // 弹窗开关
      modalVisible: false,
      // 已选指标项
      selectedIndicator: [],
      // 需要固定指标
      needFixedList: [
        { key: 'account_id', label: '账号ID' }
      ],
      // 固定指标
      fixedList: [],
      // 选中的自定义列模板
      activeTemp: null,
      // 所有配置项
      menuList: dimensionData,
      // 选中的自定义列模板
      editTempName: null,
      // 模拟数据-维度
      mockDimensionList: dimensionData,
      // 模拟数据-指标
      mockTargetList: targetData,
      // 自定义维度、指标弹窗标题
      customizeTitle: '维度',
      // 数据口径
      dataCaliberFlag: false,
      // 报表类型
      reportType: null,
      // 时间范围
      ranges: {
        //'Today': [moment().startOf('day'), moment()],
        //'Yesterday': [moment().startOf('day').subtract(1, 'days'), moment().endOf('day').subtract(1, 'days')],
        //'Last 7 Days': [moment().startOf('day').subtract(6, 'days'), moment()],
        '近30天': [moment(), moment().startOf('day').add(29, 'days'), ],
        '近60天': [moment(), moment().startOf('day').add(59, 'days')],
        '近90天': [moment(), moment().startOf('day').add(89, 'days')],
      },
      // 订阅周期
      subscribeTime: [],
      // 默认订阅周期
      defaultSubscribeTime: [moment(), moment().startOf('day').add(29, 'days'), ],
      // 选择账户数据
      selectAccountList: [],
      // 账户列表
      accountList: [],
      // 报表类型切换-维度数据
      reportTypeDimensionJson: reportTypeDimensionJson,
      // TODO 模拟数据
      mockSelectAccountList: [
        {
          id: '1',
          accountName: '芦鸣-alpha-授信-手动_01（1784340542790666）'
        },
        {
          id: '2',
          accountName: '芦鸣-alpha-授信-手动_02（1784340539521034）'
        },
        {
          id: '21',
          accountName: '芦鸣-alpha-授信-手动_21（1784340542790666）'
        },
      ],
      // 飞书成员列表
      feishuMembersList: [],
      // 飞书群列表
      feishuChatsList: [],
      // 推送时间
      pushTimesString: '',
      // 账户数量
      accountCount: 0,
    };
  },
  computed: {
    // 所有人员
    allUser() {
      return this.getAllUsers();
    },
    /*pushTimesString: {
      get() {
        return this.options.pushTimes?this.options.pushTimes.join(', '):'';
      },
      set(value) {
        this.options.pushTimes = value?value.split(',').map(item => item.trim()).filter(item => item):'';
      }
    },*/
    reportFormatList:{
      get() {
        var aa = this.options.reportFormat ? this.options.reportFormat.split('、'):'';
        // this.$message.success('设置成功:'+aa);
        return aa;
      },
      set(value) {
        this.options.reportFormat = value?value.join('、'):'';
        // this.$message.success('设置成功:'+this.options.reportFormat);
      }
    }
  },
  watch: {
    'showCustomizeReport.visible': {
      handler(val) {
        let options = null;
        if (this.showCustomizeReport.mode !== 'add' && this.getActiveTemp?.() && this.getActiveTemp?.()?.id) {
          options = this.getActiveTemp();
          let groupFieldCodes = options.groupFieldCodes ? _.cloneDeep(options.groupFieldCodes) : [];
          groupFieldCodes.forEach(item => {
            item.id = item.tiasCustomerReportId;
            item.orderNum = item.serialNumber;
            item.columnValue = item.fieldName;
            item.columnKey = item.fieldCode;
          });
          this.selectedDimensionColumns = groupFieldCodes;
          let fieldCodes = options.fieldCodes ? _.cloneDeep(options.fieldCodes) : [];
          fieldCodes.forEach(item => {
            item.id = item.tiasCustomerReportId;
            item.orderNum = item.serialNumber;
            item.columnValue = item.fieldName;
            item.columnKey = item.fieldCode;
          });
          this.selectedTargetColumns = fieldCodes;
          this.dimensionCount = this.selectedDimensionColumns.length;
          this.targetCount = this.selectedTargetColumns.length;
          if (this.dimensionCount > 0 || this.targetCount > 0) {
            this.selectedColumns = this.selectedDimensionColumns.concat(this.selectedTargetColumns);
          }
          if (options.dimension === 'mdaAccount') {
            this.fixedList = this.needFixedList;
          }
          this.mediaChange(options.media);
          this.reportType = options.reportType;
          this.pushTimesString = options.pushTimes ? options.pushTimes.join(', ') : '';
          this.accountCount = options.advertiserIds ? options.advertiserIds.length : 0;
          if (this.selectAccountList.length === 0) {
            this.selectAccountList = options.advertiserIds ? options.advertiserIds.map(item => {
              return {
                id: item.advertiserId,
                advertiserId: item.advertiserId,
                advertiserName: item.advertiserName,
                mediaChannel: item.mediaChannel,
                bz: item.bz,
                tiasCustomerReportId: item.tiasCustomerReportId,
              };
            }) : [];
          }
        }
        if (val) {
          // console.log('报表表单打开 ==> options', options);
          this.getOptions(options);
        }
      },
      immediate: true,
    },
  },
  created() {
    this.getFeishuMembersList();
    this.getFeishuChatsList();
  },
  methods: {
    // 初始化表单
     getOptions(data) {
      this.options = data || {};
      this.tempOptions = this.options;
      // 订阅周期处理
      this.getDefaultSubscribeTime(this.options.reportType);
      if (this.options.subStartDate) {
        this.subscribeTime = [this.options.subStartDate,this.options.subEndDate]
      }
      if (this.options.reportFormatList && this.options.reportFormatList.includes(1)) {
        this.dataWriteLinksFlag = true;
      }
      if (this.options.pushObjVals && this.options.pushObjVals.length > 0) {
        //过滤this.options.pushObjVals
        let groupNames = this.options.pushObjVals.filter(e=>e.objType === '飞书群').map(item=>{
          return item.objVal;
        });
        if (groupNames && groupNames.length >= 0) {
          this.pushMethodFlag = true;
          this.groupNames = groupNames;
        }
        let personNames = this.options.pushObjVals.filter(e=>e.objType === '飞书用户').map(item=>{
          return item.objVal;
        });
        if (personNames && personNames.length >= 0) {
          this.pushMethodForPersonFlag = true;
          this.personNames = personNames;
        }
      }
      if (this.options.pushTimeType && this.options.pushTimeType === 2) {
        this.pushMethodFlag = true;
        this.customPushTimes = this.options.customPushTimes;
      }
    },
    // 选择订阅账户-按钮操作
    addSubscribeAccount() {
      this.accountVisible = true;
    },
    // 选择订阅账户-确认
    handleConfirmAccount(val) {
      // console.log('选择订阅账户数据', val);
      this.accountCount = val ? val.length : 0;
      this.selectAccountList = val;
      // this.options.accountIdList = val.map(item => item.id);
    },
    // 表单确认提交
    handleOk() {
      // console.log('表单确认提交', this.options);
      // 数据校验
      if (!this.checkData(this.options)) {
        return;
      }
      // 数据处理
      this.options.advertiserIds = this.selectAccountList.map(item => {
        return {
          advertiserId: item.id,
          advertiserName: item.advertiserName,
          mediaChannel: item.mediaChannel,
          bz: item.bz,
          tiasCustomerReportId: item.tiasCustomerReportId,
        };
      });
      this.options.groupFieldCodes = this.selectedDimensionColumns.map(item => {
        return {
          fieldCode: item.columnKey,
          fieldName: item.columnValue,
          fieldType: '维度',
          serialNumber: item.orderNum,
          tiasCustomerReportId: item.id,
        };
      });
      this.options.fieldCodes = this.selectedTargetColumns.map(item => {
        return {
          fieldCode: item.columnKey,
          fieldName: item.columnValue,
          fieldType: '指标',
          serialNumber: item.orderNum,
          tiasCustomerReportId: item.id,
        };
      });
      // 报表示例
      //this.options.reportTableList = this.options.groupFieldCodes.concat(this.options.fieldCodes);
      if (this.options.dimension === 'mdaAccount') {
        this.fixedList = this.needFixedList;
        this.options.fixedList = this.needFixedList;
      }
      // moment订阅周期格式化
      if(this.subscribeTime[0] instanceof moment) {
        let date = moment(this.subscribeTime[0], 'YYYY-MM-DD');
        let date1 = moment(this.subscribeTime[1], 'YYYY-MM-DD');
        this.subscribeTime[0] = date.format('YYYY-MM-DD');
        this.subscribeTime[1] = date1.format('YYYY-MM-DD');
        this.options.subStartDate = this.subscribeTime[0] + ' 00:00:00';
        this.options.subEndDate = this.subscribeTime[1]+ ' 00:00:00';
      }
      // 推送时间类型切换处理
      /*if (this.options.pushTimeType === 1) {
        this.options.customPushTime = null;
        this.options.customPushTimes = [];
      }
      if (this.options.pushTimeType === 2) {
        this.options.customPushHourStartTime = null;
        this.options.customPushHourEndTime = null;
        this.options.customPushTimes = this.customPushTimes.filter(item => item !== null);
      }*/
      this.options.pushTimes = this.pushTimesString ? this.pushTimesString.split(',').map(item => item.trim()).filter(item => item) : [];

      /*this.options.groupNames = [];
      this.setPushMethodData(this.groupNames, this.options.groupNames);
      this.options.personNames = [];
      this.setPushMethodData(this.personNames, this.options.personNames);*/
      this.options.pushObjVals = [];
      debugger
      if (this.groupNames.length > 0) {
        this.options.pushObjVals = this.groupNames.map(item => {
          return {
            objType: '飞书群',
            objVal: this.feishuChatsList.filter(chat => chat.value === item)[0].value,
            bz: this.feishuChatsList.filter(chat => chat.value === item)[0].label,
          };
        });
      }
      if (this.personNames.length > 0) {
         let personObjects = this.personNames.map(item => {
          return {
            objType: '飞书用户',
            objVal: this.feishuMembersList.filter(member => member.value === item)[0].value,
            bz: this.feishuMembersList.filter(member => member.value === item)[0].label,
          };
        });
        this.options.pushObjVals = this.options.pushObjVals.concat(personObjects);
      }
      this.options.userId = this.$store.state.oauth.userInfo.userId;
      this.options.userName = this.$store.state.oauth.userInfo.userCode;
      // 提交数据API接口对接
      const _this = this;
      Modal.confirm({
        title: '请确认',
        okText: '确定',
        cancelText: '取消',
        content: '是否确认提交表单数据',
        async onOk() {
          try {
            const res = _this.showCustomizeReport.mode === 'add' ? await addSubscribeReport(_this.options) : await editSubscribeReport(_this.options);
            if (res.code === 0) {
              _this.showCustomizeReport.visible = false;
              _this.showCustomizeReport.refresh = true;
            }
          } catch (error) {
            let msg = _this.showCustomizeReport.mode === 'add' ? '新增报表失败:' : '编辑报表失败:';
            console.error(msg, error);
            message.error(msg);
          }
        },
        onCancel() {},
      });

    },
    // 设置推送方式数据
    setPushMethodData(vals, params) {
      let list = vals.filter(item => item !== null);
      if (list && list.length > 0) {
        params.push(...list);
      }
    },
    // 限制结束时间可以选择的小时范围
    disabledEndHours() {
      if (this.options.startTime) {
        // 确保 startTime 是 Date 类型
        if (!(this.options.startTime instanceof Date)) {
          this.options.startTime = new Date(this.options.startTime);
        }

        if (this.options.startTime instanceof Date) {
          const startHour = this.options.startTime.getHours();
          // 返回大于开始时间的小时
          return Array.from({ length: 24 }, (_, i) => i).filter(h => h < startHour);
        } else {
          console.error('Invalid startTime');
        }
      }
    },
    // 订阅时间选择器 变化 value => Moment[], dateString => string[]
    onChange(value) {

    },
    // 时间内整点推送 - 开始时间
    changePushHourStartTime(val) {

    },
    // 时间内整点推送 - 结束时间
    changePushHourEndTime(val) {

    },
    // 订阅时间选择器 确认 value Moment[]
    onOk(value) {
      // let date = moment(value, 'YYYY-MM-DD HH:mm:ss');
      // let formattedDate = date.format('YYYY-MM-DD HH:mm'); // 格式化日期
      // console.log('onOk Selected time: ', value);
    },
    // 月报-推送时间变化
    onChangeForMonthly(value) {

    },
    // 月报-推送时间确认
    onOkForMonthly(value) {

    },
    // 根据 key 获取列的标题
    getColumnTitle(key) {
      const column = this.selectedColumns.find(col => col.columnKey === key);
      return column ? column.columnValue : key;
    },
    // 添加自定义推送时间到列表
    addCustomPushTime() {
      if (this.customPushTimes.length < 12) {
        this.customPushTimes.push(null);  // 添加一个空的时间选择控件
      } else {
        this.$message.warning('最多添加12个时间组');
      }
    },
    // 添加推送方式-飞书
    addPushGroupName() {
      if (this.groupNames.length < 5) {
        this.groupNames.push(null);  // 添加一个空的控件
      } else {
        this.$message.warning('最多添加5个群组');
      }
    },
    // 添加推送方式-飞书人员
    addPushPersonName() {
      if (this.personNames.length < 5) {
        this.personNames.push(null);  // 添加一个空的控件
      } else {
        this.$message.warning('最多添加5个人员组');
      }
    },
    // 时报-处理自定义推送时间变化
    changeCustomPushTimeForHour(index) {
      // 处理时间变化的逻辑
      // console.log(`选择了第 ${index + 1} 个时间：`, this.customPushTimes[index]);
    },
    // 日报-处理推送时间变化
    changeCustomPushTimeForDay(value) {

    },
    // 周报-处理推送时间变化
    changeCustomPushTimeForWeekly(value) {

    },
    // 周时间变化
    weeklyChange(val) {

    },
    // 删除某个时间选择控件
    removeCustomPushTime(index) {
      this.customPushTimes.splice(index, 1);
    },
    // 报表格式变化
    reportFormatChange(val) {
      // this.dataWriteLinksFlag = !!val.includes(1);

      /*if (!val.includes(1)) {
        // this.options.dataWriteLinks = '';
        this.options.feishuReportExcelUrl = '';
      }*/
    },
    // 推送方式变化
    pushMethodChange(val) {
      // console.log('推送方式', val);
      if (val.length === 0) {
        this.pushMethodFlag = false;
        this.pushMethodForPersonFlag = false;
      } else if (val.length === 1) {
        if (val[0] === 1) {
          this.pushMethodFlag = true;
          this.pushMethodForPersonFlag = false;
          this.groupNames.push(null);
        } else {
          this.pushMethodFlag = false;
          this.pushMethodForPersonFlag = true;
          this.personNames.push(null);
        }
      } else if (val.length === 2) {
        this.pushMethodFlag = true;
        this.pushMethodForPersonFlag = true;
        this.groupNames.push(null);
        this.personNames.push(null);
      }
    },
    // 删除某个推送测试选择控件
    delPushTest(index) {
      this.groupNames.splice(index, 1);
    },
    // 删除某个推送测试选择控件
    delPushPersonTest(index) {
      this.personNames.splice(index, 1);
    },
    // 推送测试控件
    pushTestIndex(index) {

    },
    // 推送测试
    pushFeiShuPersonTestIndex(index) {

    },
    // 选择维度
    selectDimension() {
      this.modalVisible = true;
      this.customizeTitle = '维度';
      if (this.options.dimension === 'mdaAccount') {
        this.fixedList = this.needFixedList;
      }
      /** TODO START this.mockDimensionList如果是接口查询，则需要处理 替换为接口数据 */
      // 接口获取维度数据需要根据这个this.options.reportType值确定维度列表
      this.menuList = this.mockDimensionList;
      /*this.menuList && this.menuList.forEach(item => {
        if (item.key === 'TIME') {
          this.options.groupFieldCodes.for(groupField => {
            if (groupField.fieldCode === 'stat_time_time') {
            item.info = reportTypeDimensionJson.hour;
            } else if (groupField.fieldCode === 'stat_time_day') {
            item.info = reportTypeDimensionJson.day;
          }
          })
        }
      });*/
      /** END **/
      // this.selectedIndicator = this.options.groupFieldCodes;

      this.selectedDimensionColumns && this.selectedDimensionColumns.forEach(item => {
        this.menuList.forEach(item2 => {
          const itemInfo = item2.info.find(item3 => item.columnKey === item3.columnKey);
          // 处理选中的数据
          itemInfo && this.selectedIndicator.push(itemInfo);
        });
      });
    },
    // 选择指标
    selectTarget() {
      this.modalVisible = true;
      this.customizeTitle = '指标';
      this.fixedList = [];
      // TODO 需要处理 替换为接口数据
      this.menuList = this.mockTargetList;
      this.selectedTargetColumns && this.selectedTargetColumns.forEach(item => {
        this.menuList.forEach(item2 => {
          const itemInfo = item2.info.find(item3 => item.columnKey === item3.columnKey);
          // 处理选中的数据
          itemInfo && this.selectedIndicator.push(itemInfo);
        });
      });
    },
    // 报表类型切换
    reportTypeChange(val, e) {
      this.reportType = val;
      this.options.reportTypeCode = val;
      this.options.reportTypeName = e.label;
      this.getDefaultSubscribeTime(val);

      // 处理报表类型切换时，维度数据更新
      this.selectedDimensionColumns = this.selectedDimensionColumns.filter(item =>
        item.columnKey !== 'year_month_day'
        && item.columnKey !== 'hour'
        && item.columnKey !== 'date_range'
        && item.columnKey !== 'week'
        && item.columnKey !== 'year_month'
      );
      this.selectedColumns = this.selectedColumns.filter(item =>
        item.columnKey !== 'year_month_day'
        && item.columnKey !== 'hour'
        && item.columnKey !== 'date_range'
        && item.columnKey !== 'week'
        && item.columnKey !== 'year_month'
      );
      this.dimensionCount = this.selectedDimensionColumns.length;
    },
    // 媒体切换
    mediaChange(val, e) {
      if (val !== undefined) {
        this.dataCaliberFlag = val === 'GDT_V3';
        this.options.mediaChannel = e.label;
      }
    },
    // 数据口径切换
    dataCaliberChange(val) {

    },
    // 数据维度切换
    onDimensionChange(val) {
      if (val && val.target.value === 'mdaAccount') {
        this.fixedList = this.needFixedList;
      }
    },
    /** 获取排序后的已选指标数组
     * @param {Array} list 已选指标数组
     * @return {*}
     */
    getDraggableList(list) {
      this.selectedIndicator = list;
    },
    /** 组装报表示例表格
     * @param {*}
     * @return {*}
     */
    getTempName() {

    },
    /** 关闭维度-指标选择弹窗
     * @param {*} params
     * @return {*}
     */
    getTempData(params) {
      console.log('关闭维度-指标选择弹窗===@getTempData===', params);
      if (params) {
        if (this.customizeTitle === '维度') {
          this.selectedDimensionColumns = params;
          this.dimensionCount = params.length;
          // this.options.selectedDimensionColumns = params;
        } else {
          this.selectedTargetColumns = params;
          this.targetCount = params.length;
          // this.options.selectedTargetColumns = params;
        }
        this.selectedColumns = this.selectedDimensionColumns.concat(this.selectedTargetColumns);
        if (this.showCustomizeReport.mode === 'add') {
          this.$message.success('选择成功');
          this.modalVisible = false;
          this.selectedIndicator = [];
          this.getTempName();
        } else {
          this.$message.success('修改成功');
          this.modalVisible = false;
          this.selectedIndicator = [];
          this.getTempName();
        }
      } else {
        this.modalVisible = false;
        this.selectedIndicator = [];
      }
    },
    // 报表订阅周期处理
    getDefaultSubscribeTime(val) {
      switch (val) {
        case 1:
          this.subscribeTime = [moment(), moment().startOf('day').add(29, 'days'), ];
          break;
        case 2:
          this.subscribeTime = [moment(), moment().startOf('day').add(29, 'days'), ];
          break;
        case 3:
          this.subscribeTime = [moment(), moment().startOf('day').add(59, 'days'), ];
          break;
        case 4:
          this.subscribeTime = [moment(), moment().startOf('day').add(89, 'days'), ];
          break;
        default:
          this.subscribeTime = [moment(), moment().startOf('day').add(29, 'days'), ];
          break;
      }
    },
    /** 推送时间类型切换
     * @param {*} val
     * @return {*}
     */
    pushTimeTypeChange(val) {
      // console.log('pushTimeTypeChange', val.target.value);
      if (val.target.value === 2) {
        this.customPushTimes = [];
        this.options.customPushHourStartTime = this.tempOptions.customPushHourStartTime;
        this.options.customPushHourEndTime = this.tempOptions.customPushHourEndTime;
        if (this.tempOptions.customPushTimes && this.tempOptions.customPushTimes.length > 0) {
          this.customPushTimes = this.tempOptions.customPushTimes;
        } else {
          this.customPushTimes.push(null);
        }
      } else {
        this.customPushTimes = [];
      }
    },
    // 订阅状态切换
    subStatusClick(val) {
      if (val) {
        this.options.subStatus = '已订阅';
      } else {
        this.options.subStatus = '未订阅';
      }
    },
    /** 表单提交数据校验
     * @return {*}
     */
    checkData() {
      if (this.options.reportName === undefined || this.options.reportName === '') {
        this.$message.error('请输入报表名称');
        return false;
      }
      /*if (this.options.dimension === undefined || this.options.dimension === null) {
        this.$message.error('请选择数据维度');
        return false;
      }*/
      if (this.options.reportTypeName === undefined || this.options.reportTypeName === null) {
        this.$message.error('请选择报表类型');
        return false;
      }
      if (this.options.mediaChannel === undefined || this.options.mediaChannel === null) {
        this.$message.error('请选择媒体');
        return false;
      }
      if (this.options.media === 'GDT_V3' && (this.options.dataCaliber === undefined || this.options.dataCaliber === null)) {
        this.$message.error('请选择数据口径');
        return false;
      }
      if (this.selectAccountList === undefined || this.selectAccountList.length === 0) {
        this.$message.error('请选择订阅账户');
        return false;
      }
      if (this.dimensionCount === 0) {
        this.$message.error('请选择维度');
        return false;
      }
      if (this.targetCount === 0) {
        this.$message.error('请选择指标');
        return false;
      }
      if (this.options.subStatus === undefined || this.options.subStatus === null) {
        // this.$message.error('订阅状态不能为空');
        // return false;
        this.options.subStatus = '未订阅';
      }
      if (this.subscribeTime === undefined || this.subscribeTime.length === 0) {
        this.$message.error('请选择订阅周期');
        return false;
      }
      if (this.options.reportType === 1
        && (this.options.pushTimeType === undefined || this.options.pushTimeType === null)) {
        this.$message.error('请选择推送时间');
        return false;
      }
      if (this.options.reportType === 1 && this.options.pushTimeType === 1
        && (this.options.customPushHourStartTime === undefined || this.options.customPushHourStartTime === null)) {
        this.$message.error('时间内整点推送开始不能为空');
        return false;
      }
      if (this.options.reportType === 1 && this.options.pushTimeType === 1
        && (this.options.customPushHourEndTime === undefined || this.options.customPushHourEndTime === null)) {
        this.$message.error('时间内整点推送结束不能为空');
        return false;
      }
      if (this.options.reportType === 1 && this.options.pushTimeType === 1
        && (this.options.customPushHourStartTime > this.options.customPushHourEndTime)) {
        this.$message.error('时间内整点推送开始时间需要小于等于结束时间');
        return false;
      }
      let customPushTimes = this.customPushTimes.filter(item => item !== null);
      if (this.options.reportType === 1 && this.options.pushTimeType === 2 && customPushTimes.length === 0) {
        this.$message.error('自定义推送时间不能为空');
        return false;
      }
      if (this.options.reportType > 1
        && (this.options.customPushTime === undefined || this.options.customPushTime === null || this.options.customPushTime === '')) {
        this.$message.error('推送时间不能为空');
        return false;
      }
      if (this.options.reportType === 3
        && (this.options.weekly === undefined || this.options.weekly === null || this.options.weekly === '')) {
        this.$message.error('推送时间不能为空');
        return false;
      }
      if (this.reportFormatList === undefined || this.reportFormatList.length === 0) {
        this.$message.error('报表格式不能为空');
        return false;
      }
      if (this.reportFormatList.includes('excel')
        && (this.options.feishuReportExcelUrl === undefined || this.options.feishuReportExcelUrl === null || this.options.feishuReportExcelUrl === '')) {
        this.$message.error('数据写入链接不能为空');
        return false;
      }
      let groupNames = this.groupNames.filter(item => item !== null && item !== '' && item !== ' ');
      let personNames = this.personNames.filter(item => item !== null && item !== '' && item !== ' ');

      if (groupNames.length === 0 && personNames.length === 0) {
        this.$message.error('推送信息对象不能都为空!!!');
        return false;
      }
      /*if (this.options.pushMethodList === undefined || this.options.pushMethodList.length === 0) {
        this.$message.error('推送方式不能为空');
        return false;
      }*/
      /*let groupNames = this.groupNames.filter(item => item !== null && item !== '' && item !== ' ');
      if (this.options.pushMethodList.includes(1) && groupNames.length === 0) {
        this.$message.error('推送方式飞书群名称不能为空');
        return false;
      }
      let personNames = this.personNames.filter(item => item !== null && item !== '' && item !== ' ');
      if (this.options.pushMethodList.includes(2) && personNames.length === 0) {
        this.$message.error('推送方式飞书人员名称不能为空');
        return false;
      }*/
      return true;
    },
    // 获取飞书成员列表
    async getFeishuMembersList() {
      try {
        const res = await getFeishuMembers();
        if (res.code === 0) {
          this.feishuMembersList = res.data.map(item => ({
            value: item.feishuUserId,
            label: item.name,
          }));
        }
      } catch (error) {
        console.error('获取飞书成员列表失败:', error);
        message.error('获取飞书成员列表失败');
      }
    },
    // 获取飞书群列表
    async getFeishuChatsList() {
      try {
        const res = await getFeishuChats();
        if (res.code === 0) {
          this.feishuChatsList = res.data.map(item => ({
            value: item.chatId,
            label: item.name
          }));
        }
      } catch (error) {
        console.error('获取飞书群列表失败:', error);
        message.error('获取飞书群列表失败');
      }
    },
    // 在组件挂载时获取飞书成员列表
    mounted() {
      this.getFeishuMembersList();
      this.getFeishuChatsList();
    },
  },
};
</script>

<style scoped lang="less">
.customize-report-wrapper {
  /*background: rgb(240, 242, 245);*/
  background: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  .report-header {
    margin-top: 10px;
    width: 200px;
    height: 36px;
    line-height: 34px;
    .header-text {
      line-height: 34px;
      font-size: 14px;
      /*color: #2f70f4;*/
    }
  }
  .report-body {
    //height: 200px;
    flex-grow: 1;
    border-radius: 8px;
    padding-right: 7px;
    overflow-y: auto;
    .report-module {
      border-radius: 8px;
      background: #fff;
      .module-title {
        height: 45px;
        line-height: 45px;
        border-bottom: 1px solid rgb(245, 247, 250);
        color: #333;
        font-size: 14px;
        &::before {
          content: '';
          display: inline-block;
          height: 12px;
          width: 4px;
          background-color: #2f70f4;
          margin-right: 10px;
          transform: translateY(1px);
        }
      }
      .module-content {
        padding: 12px 15px;
        .report-list {
          height: 80vh;
          .report-item:not(:last-child) {
            margin-bottom: 24px;
          }
          :deep(.select-multiple) .ant-select-selection-item {
            max-width: calc(100% - 80px) !important;
          }
        }
      }
      &:not(:first-child) {
        margin-top: 12px;
      }
      &.hosting-range {
        .all-selected-wrapper {
          border-radius: 4px;
          border: 1px solid #e6e9f1;
          .select-filter {
            padding: 13px;
            overflow: hidden;
            .ant-radio-group {
              margin: 23px 0 20px 0;
              display: block;
            }
          }
          .all-selected {
            background: #f9f9f9;
            display: flex;
            padding: 16px 0 4px 0;
            .selected-number {
              width: 90px;
              padding-left: 12px;
            }
            .all-selected-list {
              width: calc(100% - 139px);
              height: 100%;
              display: flex;
              flex-wrap: wrap;
              .all-selected-item {
                border-radius: 4px;
                background: #fff;
                border: 1px solid #e6e9f1;
                padding: 7px 9px;
                position: relative;
                margin-bottom: 12px;
                margin-right: 12px;
                p {
                  line-height: 16px;
                  color: #333;
                  &.id {
                    color: #ababab;
                  }
                }
                .delete-icon {
                  position: absolute;
                  right: -6px;
                  top: -6px;
                  cursor: pointer;
                  z-index: 2;
                  color: #5e5e5e;
                }
              }
            }
            .clear-all {
              color: #929292;
              cursor: pointer;
              width: 40px;
              margin: 0 9px;
              height: 25px;
              text-align: center;
              &:hover {
                background: rgb(240, 242, 245);
                color: black;
                cursor: pointer;
              }
            }
          }
        }
      }
      &.trigger-condition {
        .report-list .report-item:first-child .radio-group {
          margin-left: 76px;
        }
      }
      &.notify-setting {
        .report-item:not(:last-child) {
          .report-item-label {
            display: inline-block;
            width: 110px;
            font-weight: bold;
          }
          :deep(.ant-checkbox-group) {
            .ant-checkbox-wrapper {
              // 暂时屏蔽而更改的样式
              width: 185px;
              &.specific-user {
                width: 85px;
              }
            }
          }
        }
      }
      &.report-name {
        height: 54px;
        line-height: 54px;
        padding-right: 24px;
        .report-item-label {
          font-weight: bold;
          margin: 0 7px 0 12px;
        }
      }
      .report-item-label {
        color: #333;
        font-size: 12px;
      }
    }

    :deep(.ant-time-picker-input),
    :deep(.ant-calendar-picker-input),
    :deep(.ant-select-selector) {
      height: 30px !important;
      // padding: 0 11px;
    }
    :deep(.ant-select-multiple) .ant-select-selector {
      padding: 0 4px;
    }
  }
}
.report-item-label {
  width: 80px;
}
.vertical-line {
  border-right: 2px solid rgb(215,215,215); /* 创建左侧竖线 */
  height: 15px; /* 设置竖线的高度 */
  margin: 0 15px 0 0;
}
.footer {
  background-color: #FFFFFF;
  display: flex;
  margin: 20px 0 20px 104px;
}

/* 鼠标悬停时的样式 */
.target-button:hover {
  color: #1890ff; /* 改变图标颜色，您可以使用任何颜色 */
}

.target-button {
  background-color: rgb(242,242,242);
  color: #5e5e5e; /* 改变图标颜色，您可以使用任何颜色 */
}

.report-header-close-button:hover {
  color: #1890ff; /* 改变图标颜色，您可以使用任何颜色 */
}

.report-header-close-button {
  background-color: #FFFFFF;
  color: #000000;
}
</style>
<style lang="less">
.w64 {
  width: 64px;
}
.w120 {
  width: 120px;
}
.w176 {
  width: 176px;
}
.w256 {
  width: 256px;
}
.mr12 {
  margin-right: 12px;
}
.line-h32 {
  line-height: 32px;
}
</style>
