<template>
  <div class="rules-list-wrapper">
    <div class="toolbar-wrapper">
      <div class="filter-select">
        <a-form layout="horizontal" :model="filterOptions" class="filter-container">
          <a-row>
            <a-col span="4">
              <a-form-item label="报表名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" size="small">
                <a-input v-model:value="filterOptions.reportName" placeholder="搜索报表名称" allow-clear></a-input>
              </a-form-item>
            </a-col>
            <a-col span="4">
              <a-form-item label="报表类型" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
                <a-select v-model:value="filterOptions.reportTypeName" :options="optionList.reportTypeName" style="width: 100%" placeholder="请选择报表类型" v-bind="$filterOptions"></a-select>
              </a-form-item>
            </a-col>
            <a-col span="4">
              <a-form-item label="媒体" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
                <a-select v-model:value="filterOptions.mediaChannel" :options="optionList.mediaChannel" style="width: 100%" placeholder="请选择媒体" v-bind="$filterOptions"></a-select>
              </a-form-item>
            </a-col>
            <a-col span="6" class="pl2em">
              <a-form-item>
                <a-space>
                  <a-button type="primary" @click="doFilter" :disabled="tableLoading">筛选</a-button>
                  <a-button @click="resetFilter" :disabled="tableLoading">重置筛选</a-button>
                </a-space>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col span="6">
              <a-form-item>
                <a-button type="primary" style="margin-right: 12px" @click="openAddTempModal"><PlusCircleOutlined />新增报表</a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </div>
    <div class="rules-table-wrapper">
      <a-table v-bind="table" :pagingation="table.pagination" :data-source="tableData.dataSource" :loading="tableData.tableLoading" row-key="id" @change="tableChange">
        <template v-for="column in $attrs.columns" :key="column.dataIndex">
          <!-- 开关 -->
          <a-table-column v-if="column.dataIndex === 'subStatus'" v-bind="column">
            <template #default="{ record }">
            <!--record.subStatus == '已订阅'  a-switch则打开，否则关闭-->
<!--
              <a-switch :loading="record.switchLoading" :checked="!!record.status" :disabled="!!record.switchDisabled" size="small" @change="(changed, e) => updateReportStatusFun(changed, e, record)" />
-->
              <a-switch :checked="record.subStatus === '已订阅' " size="small" @change="(changed, e) => updateReportStatusFun(changed, e, record)" />
            </template>
          </a-table-column>
          <!-- 报表类型 -->
          <a-table-column v-else-if="column.dataIndex === 'reportType'" v-bind="column">
            <template #default="{ record }">
              <span>{{ getReportTypeLabel(record.reportType) }}</span>
            </template>
          </a-table-column>
          <!-- 媒体 -->
          <a-table-column v-else-if="column.dataIndex === 'media'" v-bind="column">
            <template #default="{ record }">
              <span>{{ getReportMediaLabel(record.media) }}</span>
            </template>
          </a-table-column>
          <!--订阅到期时间 -->
          <a-table-column v-else-if="column.dataIndex === 'subscriptionExpirationTime'" v-bind="column">
            <template #default="{ record }">
              <span v-if="record.isExpiration" style="color: red">{{ record.subscriptionExpirationTime && moment(record.subscriptionExpirationTime).format('YYYY-MM-DD') }}</span>
              <span v-else>{{ record.subscriptionExpirationTime && moment(record.subscriptionExpirationTime).format('YYYY-MM-DD') }}</span>
            </template>
          </a-table-column>
          <!--创建时间 -->
          <a-table-column v-else-if="column.dataIndex == 'createTime'" v-bind="column">
            <template #default="{ record }">
              <span>{{ record.createTime && moment(record.createTime).format('YYYY-MM-DD HH:ss') }}</span>
            </template>
          </a-table-column>
          <!-- 操作 -->
          <a-table-column v-else-if="column.dataIndex == 'operate'" v-bind="column">
            <template #default="{ record }">
              <a-button 
                type="link" 
                class="mr10 cursorPoi"
                :loading="executingId === record.id"
                @click="handleDoReports(record)"
                style="margin-left:20px;padding:0; height: auto; line-height: inherit;"
              >
                执行
              </a-button>
              <a class="mr10 cursorPoi" @click="editReports(record)">编辑</a>
              <a class="mr10 cursorPoi" @click="handleDeleteReports(record)">删除</a>
            </template>
          </a-table-column>
          <!-- 其他 -->
          <a-table-column v-else v-bind="column">
            <template #default="{ record }">
              <span> {{ record?.[column.dataIndex] || '-' }}</span>
            </template>
          </a-table-column>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { PlusCircleOutlined } from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';
export default

{
  name: 'CustomerReportsTable',
  components: { PlusCircleOutlined },
  inject: ['showCustomizeReport', 'tableData', 'getReportsTableData', 'editReports', 'deleteReports','doReports', 'updateReportStatusFun', 'table', 'getAllUsers'],
  provide() {
    return {
      showCustomizeReport: this.showCustomizeReport,
    };
  },
  data() {
    return {
      loginUserId : this.$store.state.oauth.userInfo.userId,
      loginGrades : this.$store.state.oauth.userInfo.grades,
      moment,
      // 筛选数据
      filterOptions: {},
      // 下拉框选项集合
      optionList: {
        // 报表类型
        reportTypeName: [
          { value: null, label: '全部' },
          { value: '时报', label: '时报' },
          { value: '日报', label: '日报' },
          { value: '周报', label: '周报' },
          { value: '月报', label: '月报' },
        ],
        // 媒体渠道
        mediaChannel: [
          { value: '', label: '全部' },
          { value: '巨量广告2.0', label: '巨量广告2.0' },
          { value: '巨量千川', label: '巨量千川' },
          { value: '腾讯广告3.0', label: '腾讯广告3.0' },
          { value: '爱奇艺', label: '爱奇艺' },
        ],
      },
      // 选中的表格
      selectRow: {},
      showCustomizeReport: {
        visible: false,
        refresh: false,
        mode: 'add',
      },
      tableLoading: false,
      // 添加执行loading状态
      executingId: null,
    };
  },
  computed: {
    userIds() {
      return this.getAllUsers();
    },
  },
  methods: {
    async doFilter() {
      this.tableLoading = true;
      this.filterOptions.current = this.table.pagination.current?this.table.pagination.current:1;
      this.filterOptions.size = this.table.pagination.pageSize?this.table.pagination.pageSize:10;
      this.filterOptions.userId = this.loginUserId
      await this.getReportsTableData(this.filterOptions);
      this.tableLoading = false;
    },
    async resetFilter() {
      this.tableLoading = true;
      this.filterOptions = {};
      this.filterOptions.current = '1';
      this.filterOptions.size = 20;
      await this.getReportsTableData(this.filterOptions);
      this.tableLoading = false;
    },
    /**
     * @description: 打开新增弹窗
     * @return {*}
     */
    openAddTempModal() {
      this.$emit('showCustomizeReport', {
        mode: 'add',
        visible: true,
      });
    },
    /**
     * @description: 表格页码切换
     * @param {*} pagination
     * @param {*} filters
     * @param {*} sorter
     * @return {*}
     */
    tableChange(pagination, filters, sorter) {
      this.table.pagination.current = pagination.current.toString();
      this.table.pagination.pageSize = pagination.pageSize;
      this.filterOptions.current = pagination.current.toString();
      this.filterOptions.size = pagination.pageSize.toString();
      this.getReportsTableData(this.filterOptions);
    },
    /**
     * @description: 转换报表类型
     * @param {*} value
     * @return {*}
     */
    getReportTypeLabel(value) {
      const item = this.optionList.reportType.find((option) => option.value === value);
      return item ? item.label : '未知类型';
    },
    /**
     * @description: 转换媒体
     * @param {*} value
     * @return {*}
     */
    getReportMediaLabel(value) {
      const item = this.optionList.media.find((option) => option.value === value);
      return item ? item.label : '未知类型';
    },
    /**
     * @description: 处理执行报表
     * @param {*} record
     */
    async handleDoReports(record) {
      try {
        const result = await new Promise((resolve) => {
          Modal.confirm({
            title: '确认执行',
            content: `确定要执行报表"${record.reportName}"吗？`,
            okText: '确认',
            cancelText: '取消',
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
          });
        });
        
        if (result) {
          this.executingId = record.id;
          await this.doReports(record);
        }
      } catch (error) {
        console.error('执行报表失败:', error);
      } finally {
        this.executingId = null;
      }
    },
    /**
     * @description: 处理删除报表
     * @param {*} record
     */
    async handleDeleteReports(record) {
      try {
        const result = await new Promise((resolve) => {
          Modal.confirm({
            title: '确认删除',
            content: `确定要删除报表"${record.reportName}"吗？`,
            okText: '确认',
            cancelText: '取消',
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
          });
        });
        
        if (result) {
          await this.deleteReports(record);
        }
      } catch (error) {
        console.error('删除报表失败:', error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.rules-list-wrapper {
  .toolbar-wrapper {
    .filter-select {
      &-item {
        width: 6.75rem;
        margin-bottom: 10px;
        .ant-select {
          width: 93%;
          :deep(.ant-select-selection-item) {
            max-width: 65%;
          }
        }
      }
    }
    .filter-other {
      height: 32px;

      &-input {
        width: 224px;
        margin-right: 12px;
        height: 32px;
      }
    }
  }
  .rules-table-wrapper {
    :deep(.ant-table-row):first-child {
      td:not(:nth-child(2)) > span {
        color: #000;
      }
    }

    :deep(.ant-table-row) {
      height: 48px !important;
      .ant-table-row-cell-break-word {
        height: 48px;
      }
    }
  }
}
.filter-container {
  :deep(.ant-form-item-label) {
    line-height: 40px;
  }
}
</style>