<template>
  <div class="star-task-container">
    <div class="form-card">
      <h2 class="form-title">星图任务配置</h2>
      <a-form :model="formState" name="starTaskForm" class="star-task-form" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <div class="form-section">
          <div class="section-title">认证信息</div>
          <a-form-item label="Cookie" name="cookie">
            <a-textarea v-model:value="formState.cookie" placeholder="请输入Cookie" :auto-size="{ minRows: 3, maxRows: 6 }" class="custom-textarea" />
          </a-form-item>
          <a-form-item label="X-CSRF-Token" name="xCsrfToken">
            <a-textarea v-model:value="formState.xCsrfToken" placeholder="请输入X-CSRF-Token" :auto-size="{ minRows: 3, maxRows: 6 }" class="custom-textarea" />
          </a-form-item>
          <a-form-item label="X-SecSDK-CSRF-Token" name="xSecsdk">
            <a-textarea v-model:value="formState.xSecsdk" placeholder="请输入X-SecSDK-CSRF-Token" :auto-size="{ minRows: 3, maxRows: 6 }" class="custom-textarea" />
          </a-form-item>
          <a-form-item label="批次" name="xSecsdk">
            <a-textarea v-model:value="formState.batchNumber" placeholder="请输入批次" :auto-size="{ minRows: 3, maxRows: 6 }" class="custom-textarea" />
          </a-form-item>
        </div>

        <div class="form-section">
          <div class="section-title">接收配置</div>
          <a-form-item label="接收群组" name="chatGroupName">
            <a-textarea v-model:value="formState.chatGroupName" placeholder="请输入接收群组" :auto-size="{ minRows: 2, maxRows: 4 }" class="custom-textarea" />
          </a-form-item>
          <a-form-item label="接收群组ID" name="chatGroupId">
            <a-textarea v-model:value="formState.chatGroupId" placeholder="请输入接收群组ID" :auto-size="{ minRows: 2, maxRows: 4 }" class="custom-textarea" />
          </a-form-item>

          <a-form-item label="接收人" name="userName">
            <a-textarea v-model:value="formState.userName" placeholder="请输入接收人" :auto-size="{ minRows: 2, maxRows: 4 }" class="custom-textarea" />
          </a-form-item>

          <a-form-item label="接收人ID" name="userId">
            <a-textarea v-model:value="formState.userId" placeholder="请输入接收人" :auto-size="{ minRows: 2, maxRows: 4 }" class="custom-textarea" />
          </a-form-item>
        </div>
      </a-form>
      <div class="button-group">
        <a-button type="primary" @click="showSaveConfirmModal" :loading="saveLoading" class="custom-button">保存配置</a-button>
        <a-button type="primary" @click="showConfirmModal" :loading="executeLoading" class="custom-button execute-button">执行任务</a-button>
      </div>
    </div>
    <a-modal
      v-model:visible="saveConfirmModalVisible"
      title="确认保存"
      @ok="handleSaveCookie"
      @cancel="saveConfirmModalVisible = false"
      okText="确认"
      cancelText="取消"
    >
      <p>确定要保存当前配置吗？</p>
    </a-modal>
    <a-modal
      v-model:visible="confirmModalVisible"
      title="确认执行"
      @ok="handleExecute"
      @cancel="confirmModalVisible = false"
      okText="确认"
      cancelText="取消"
    >
      <p>确定要执行星图任务吗？</p>
    </a-modal>
  </div>
</template>

<script>
import { message } from 'ant-design-vue';
import axios from '@/utils/axios';
// import axios from 'axios';

export default {
  name: 'StarTask',
  data() {
    return {
      formState: {
        cookie: '111',
        xCsrfToken: '222',
        xSecsdk: '333',
        batchNumber: new Date().toLocaleString('zh-CN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        }).replace(/\//g, '-'), //当前时间 yyyy-mm-dd hh:mm:ss
        chatGroupName: '巨量星图数据-艾嘉',
        chatGroupId: 'oc_e09ba1827ce6df8f204760c48ab7a84c',
        userName: '恩泰',
        userId: '9fb85ffb'
      },
      confirmModalVisible: false,
      saveConfirmModalVisible: false,
      executeLoading: false,
      saveLoading: false
    }
  },
  methods: {
    showSaveConfirmModal() {
      this.saveConfirmModalVisible = true;
    },
    async handleSaveCookie() {
      this.saveConfirmModalVisible = false;
      this.saveLoading = true;
      try {
        // TODO: 实现保存cookie的逻辑
        //模拟接口调用延迟2s返回数据
        await new Promise(resolve => setTimeout(resolve, 2000));
        console.log('保存cookie', this.formState);
        message.success('配置保存成功');
      } catch (error) {
        console.error('保存配置出错:', error);
        message.error('保存配置失败');
      } finally {
        this.saveLoading = false;
      }
    },
    showConfirmModal() {
      this.confirmModalVisible = true;
    },
    async handleExecute() {
      this.confirmModalVisible = false;
      this.executeLoading = true;
      try {
        const response =  axios.post('/xt_task/', this.formState);
        message.success('任务已经提交！');
      } catch (error) {
        console.error('执行任务出错:', error);
        message.error('执行任务失败，请检查网络连接');
      } finally {
        this.executeLoading = false;
      }
    }
  }
}
</script>

<style lang="less" scoped>
.star-task-container {
  padding: 12px;
  background-color: #f5f5f5;
  height: 90vh;
  overflow: hidden;

  .form-card {
    max-width: 800px;
    margin: 0 auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;

    .form-title {
      font-size: 18px;
      font-weight: 500;
      color: #1f1f1f;
      margin-bottom: 16px;
      text-align: center;
    }

    .form-section {
      margin-bottom: 16px;
      padding: 12px;
      background: #fafafa;
      border-radius: 6px;

      .section-title {
        font-size: 15px;
        font-weight: 500;
        color: #333;
        margin-bottom: 12px;
        padding-left: 10px;
        border-left: 3px solid #1890ff;
      }
    }

    .star-task-form {
      :deep(.ant-form-item) {
        margin-bottom: 12px;
      }

      :deep(.ant-form-item-label > label) {
        color: #666;
        font-weight: 500;
      }

      .custom-textarea {
        border-radius: 4px;
        border-color: #d9d9d9;
        transition: all 0.3s;

        &:hover, &:focus {
          border-color: #40a9ff;
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
        }
      }
    }

    .button-group {
      margin-top: 16px;
      text-align: center;
      padding-top: 16px;
      border-top: 1px solid #f0f0f0;

      .custom-button {
        height: 32px;
        padding: 0 20px;
        font-size: 14px;
        border-radius: 4px;
        margin: 0 8px;

        &.execute-button {
          background: #52c41a;
          border-color: #52c41a;

          &:hover {
            background: #73d13d;
            border-color: #73d13d;
          }
        }
      }
    }
  }
}
</style>